import styled from 'styled-components';

export const style = {
  messi: {
    margin: '4px auto 0px',
    height: '416px',
    maxWidth: '296px'
  } as React.CSSProperties,
  logo: {
    width: '42px',
    height: '9px',
  } as React.CSSProperties,
}

export const Background = styled.div`
  background: linear-gradient(199.29deg, #FE5E94 7.65%, #AE73D6 78.61%);
`

export const Main = styled.main`
  margin: 0 auto;
  max-width: 393px;
  min-height: calc(100vh - 47px - 47px - 9px);
  position: relative;
  padding-top: 20px;
`

export const Title = styled.div`
  text-align: center;
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  color: #FFF;
  margin-bottom: 12px;
`

export const ListWrapper = styled.div`
  position: relative;
  margin: 0 auto 61px;
  max-width: 316px;
  max-height: 392px;

  & .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0;
    &.slick-active > :first-child {
      background: #0F0F0F !important;
    }
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const ButtonWrapper = styled.div`
  position: relative;
  width: 345px;
  height: 48px;
  margin: 0 auto 39px;
`

export const DivideLine = styled.div`
  border: 3.5px dashed ${(props:{color?:string}) => props.color ?? '#FFF'};
  margin: 0 30px 34px;
`

export const StoryWrapper = styled.section`
  width: 360px;
  margin: 0 auto 53px;

  & > h2 {
    font-family: 'NanumSquareNeoHeavy';
    font-size: 20px;
    color: #FFF;
    text-align: center;
    margin-bottom: 10px;
  }
`

export const OtherTypeSection = styled.section`
  h2 {
    font-family: 'NanumSquareNeoHeavy';
    color: #FFF;
    font-size: 20px;
    line-height: 30px;
    width: max-content;
    margin: 0 auto 30px;
  }
`

export const CategorySlideWrap = styled.div`
  margin-bottom: 60px;
`
