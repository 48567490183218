import React, {  useContext, useEffect, useRef } from 'react';
import { inputKeyDownNumberType, regexPhoneNumber } from 'src/common/util/regex';
import { SignInContextDispatch } from 'src/common/variables/context';
import useInput from 'src/hook/useInput';
import { SigninContext } from 'src/pages/SignIn';
import { Container, Heading } from 'src/style/Signup';
import { Input, InputWrapper1 } from 'src/style/Signup/phoneNumber';

const SignInPhoneNumber = () => {

  const { state, dispatch } = useContext(SigninContext);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const [phoneNumber, setPhoneNumber] = useInput('', regexPhoneNumber, phoneNumberRef, {blurUpScroll:true});

  useEffect(() => {
    if (!phoneNumberRef.current) return;
    phoneNumberRef.current.focus();
  }, [])

  useEffect(() => {
    if (phoneNumber.length == 11) {
      dispatch({type:SignInContextDispatch.setPhoneNumber, payload:phoneNumber});
    } else {
      dispatch({type:SignInContextDispatch.setPhoneNumber, payload:''});
    }
  }, [phoneNumber])

  return (
    <Container>
      <Heading>
        <div>휴대폰번호로 로그인</div>
        <div className="small">서비스 인증을 위해 번호 인증을 해주세요</div>
      </Heading>
      <InputWrapper1>
        <Input
        type="number"
        onKeyPress={inputKeyDownNumberType}
        placeholder="01012345678"
        ref={phoneNumberRef}
        value={phoneNumber}
        onChange={setPhoneNumber}
        className="phoneNumber"
        />
        {/* <CountryCode>
          <CountryImg><SVG.Korea /></CountryImg>
          <CountryCodeNumber>+82</CountryCodeNumber>
        </CountryCode> */}
      </InputWrapper1>
    </Container>
  );
};

export default SignInPhoneNumber;