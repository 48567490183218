import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { httpErrorCodes } from 'src/common/fetch/errorCode';
import { requestPhoneCert, requestVerifyPhoneCert } from 'src/common/fetch/fetchs';
import { inputKeyDownNumberType } from 'src/common/util/regex';
import { SignInContextDispatch } from 'src/common/variables/context';
import useTimer from 'src/hook/useTimer';
import { SigninContext } from 'src/pages/SignIn';
import { Container, Heading } from 'src/style/Signup';
import { Input, InputsWrapper, InputWrapper2, ReValidateButton, Timer } from 'src/style/Signup/phoneNumber';
import { AuthContext } from '../context/auth';
import GlobalLoading from '../GlobalLoading';

const SignInPhoneNumberValidate = () => {

  const history = useHistory();
  const { setAuth } = useContext(AuthContext);
  const { state, dispatch } = useContext(SigninContext);
  const { time, status:timerStatus, startTimer, quitTimer } = useTimer();
  const [validateNumber, setValidateNumber] = useState(Array(6).fill(true).map(() => ''));
  const [checkingValidate, setCheckingValidate] = useState(false);

  const validateInputWrapperRef = useRef<HTMLDivElement>(null);
  const validateInputRefs = useRef<null[]|HTMLInputElement[]>(Array(6).fill(true).map(() => null));

  const ReValidatePhoneCert = async() => {
    const response = await requestPhoneCert({phoneNumber:state.phoneNumber});
    if (response.error) {
      console.error("인증번호 재요청 에러");
      return;
    }

    setValidateNumber(Array(6).fill(true).map(() => ''));
    startTimer();
    validateInputRefs.current[0]?.focus();
    dispatch({type:SignInContextDispatch.setPhoneNumberValidate, payload:false});
  }

  const verifyPhoneCert = async() => {
    const isEmpty = validateNumber.filter(i => !i).length > 0;
    if (isEmpty) {
      dispatch({type:SignInContextDispatch.setPhoneNumberValidate, payload:false});
      return;
    }
    if (checkingValidate) return;
    if (!timerStatus) {
      alert('인증시간 만료');
      return;
    }

    const requestBody = { phoneNumber: state.phoneNumber, certNumber: validateNumber.join('') };
    
    setCheckingValidate(true);
    const response = await requestVerifyPhoneCert<{data:{accessToken:string}}>(requestBody);
    setCheckingValidate(false);

    if (response.error || !response.data) {
      if (!response.data) {
        alert('인증간에 문제가 발생했습니다.\n새로고침 후 재시도해주세요.');
      }
      else if (response.error.errorCode === httpErrorCodes.verify.VERIFICATION_CODE_ERROR) {
        alert('인증번호가 불일치합니다.\n다시 시도해주세요.');
      }
      else if (response.error.errorCode === httpErrorCodes.verify.CERT_NUMBER_EXPIRED) {
        alert('인증시간이 만료되었습니다.');
      }
      else if (response.error.errorCode === httpErrorCodes.verify.CERT_NUMBER_TOO_MANY_TRY) {
        alert('인증 횟수가 초과되었습니다.\n잠시 후에 다시 시도해주세요.');
      }
      else {
        alert('인증간에 문제가 발생했습니다.\n새로고침 후 재시도해주세요.');
      }
      return;
    }

    if (!response.data.data.accessToken) {
      alert('존재하는 회원이 아닙니다.\n회원가입을 진행해주세요.');
      history.push('/signup');
      return;
    }

    // signin success
    quitTimer();
    setAuth(response.data.data.accessToken as string, () => {
      // history.goBack();
      history.push('/');
    });
  }

  const switchFocusValidateInputs = () => {
    const parent = validateInputWrapperRef.current;
    const focusEl = document.activeElement;
    if (!parent) return;
    if (!parent.contains(focusEl)) return;

    let nextFocusSiblingElIdx = 0;
    Array.from(parent.children).forEach((inputWrap, idx) => {
      if (inputWrap.children[0] === focusEl) {
        nextFocusSiblingElIdx = idx + 1;
      }
    })

    if (nextFocusSiblingElIdx == validateNumber.length) {
      verifyPhoneCert();
      return;
    }
    validateInputRefs.current[nextFocusSiblingElIdx]?.focus();
  }

  const onChangeValidateNumber = (e:ChangeEvent<HTMLInputElement>, changeIdx:number) => {
    const value = e.target.value;
    if (value.match(/\D/)) return;
    if (value.length > 1) return;
    setValidateNumber(state => state.map((i, idx) => idx == changeIdx ? value : i));
  }

  useEffect(() => {
    switchFocusValidateInputs();
  }, [validateNumber])
  
  useEffect(() => {
    validateInputRefs.current[0]?.focus();
    startTimer();
  }, [])

  return (
    <Container>
      <Heading>
        <div>인증번호를 입력해주세요</div>
        <div className="small">+82{state.phoneNumber.slice(1)}로 전송됨</div>
      </Heading>

      <InputsWrapper ref={validateInputWrapperRef}>
        {validateNumber.map((i, idx) => (
          <InputWrapper2 key={idx}>
            <Input
            ref={el => {validateInputRefs.current[idx] = el}}
            type="number"
            onKeyPress={inputKeyDownNumberType}
            value={i}
            onChange={(e) => onChangeValidateNumber(e, idx)}
            />
          </InputWrapper2>
        ))}
      </InputsWrapper>

      <Timer>{time}</Timer>

      <ReValidateButton
      onClick={ReValidatePhoneCert}
      >인증번호 다시 받기
      </ReValidateButton>

      {checkingValidate && (
        <GlobalLoading />
      )}
    </Container>
  );
};

export default SignInPhoneNumberValidate;