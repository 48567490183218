import React, { useContext, useReducer, createContext, useEffect, useState } from 'react';
import SignUpUserName from 'src/component/SignUp/SignUpUserName';
import SignUpBirth from 'src/component/SignUp/SignUpBirth';
import SignUpGenderView from 'src/component/SignUp/SignUpGenderView';
import SignUpPhoneNumber from 'src/component/SignUp/SignUpPhoneNumber';
import SignUpWelcome from 'src/component/SignUp/SignUpWelcome';
import { BackArrow, Background, Contents, Header, Logo, Main } from 'src/style/Signup';
import SVG from 'src/assets/images/svg/SVG';
import { useHistory } from 'react-router-dom';
import SignUpNextStep from 'src/component/SignUp/SignUpNextStep';
import { SignUpContextDispatch } from 'src/common/variables/context';
import { AuthContext } from 'src/component/context/auth';
import GlobalLoading from 'src/component/GlobalLoading';
import useMountedUpScroll from 'src/hook/useMountedUpScroll';
import Footer from 'src/component/Footer';

export enum SignUpStep {
  USERNAME,
  BIRTH,
  GENDER,
  PHONENUMBER,
  PHONENUMBER_AUTHENTICATE,
  WELCOME
}

interface ISignupState {
  username: string;
  birth: string;
  phoneNumber: string;
  gender?: 'MALE' | 'FEMALE';
  step: SignUpStep;
  phoneNumberValidate: boolean;
}

interface ISignupAction {
  type: string;
  payload?: any;
}

const initialState:ISignupState = {
  username: '',
  birth: '',
  phoneNumber: '',
  phoneNumberValidate: false,
  gender: undefined,
  step: SignUpStep.USERNAME
}

function reducer(state:ISignupState, action:ISignupAction) {
  switch(action.type) {
    case SignUpContextDispatch.setUserName: {
      return {...state, username: action.payload};
    }
    case SignUpContextDispatch.setBirth: {
      return {...state, birth: action.payload};
    }
    case SignUpContextDispatch.setPhoneNumber: {
      return {...state, phoneNumber: action.payload};
    }
    case SignUpContextDispatch.setPhoneNumberValidate: {
      return {...state, phoneNumberValidate: action.payload};
    }
    case SignUpContextDispatch.setGender: {
      return {...state, gender: action.payload};
    }
    case SignUpContextDispatch.setAge: {
      return {...state, age: action.payload};
    }
    case SignUpContextDispatch.setNextStep: {
      return {...state, step: state.step + 1}
    }
    default:
      return state;
  }
}

export const SignupContext = createContext<{
  state: ISignupState,
  dispatch: React.Dispatch<ISignupAction>
}>({
  state: initialState,
  dispatch: () => null
})

function Signup() {
  useMountedUpScroll();
  
  const { state:AuthState } = useContext(AuthContext);
  const { state } = useContext(SignupContext);
  const history = useHistory();
  const [transactionHash, setTransactionHash] = useState('');
  const [fetchingSignUp, setFetchingSignUp] = useState(false);

  useEffect(() => {
    if (AuthState.member) {
      history.push('/');
    }
  }, [])

  if (!AuthState.signChecked) return <></>;

  return (
    <Background>
      <Main>
        <Header>
          <BackArrow 
          className={state.step === SignUpStep.WELCOME ? 'hidden' : ''}
          onClick={() => history.goBack()}>
            <SVG.LeftWhite />
          </BackArrow>
          <Logo onClick={() => history.push('/')}>
            <SVG.LogoWSTI />
          </Logo>
        </Header>
        <Contents className={state.step === SignUpStep.WELCOME ? 'welcome' : ''}>
          {
            state.step === SignUpStep.USERNAME ? <SignUpUserName /> :
            state.step === SignUpStep.BIRTH ? <SignUpBirth /> :
            state.step === SignUpStep.GENDER ? <SignUpGenderView /> :
            (state.step === SignUpStep.PHONENUMBER || state.step === SignUpStep.PHONENUMBER_AUTHENTICATE) ? <SignUpPhoneNumber /> :
            <SignUpWelcome transactionHash={transactionHash}/>
          }
        </Contents>
        <SignUpNextStep 
        setFetchingSignUp={setFetchingSignUp}
        setTransactionHash={setTransactionHash}/>
      </Main>
      <Footer />
      {fetchingSignUp && <GlobalLoading />}
    </Background>
  )
};

export default function SignupProvider() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SignupContext.Provider value={{state, dispatch}}>
      <Signup />
    </SignupContext.Provider>
  )
}
