import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import SVG from 'src/assets/images/svg/SVG';
import styled, { css } from 'styled-components';
import BackBtn from '../BackBtn';
import MyInfoBtn from '../MyInfoBtn';

type TLogoStyle = 'Default' | 'White';
type TStyle = {
  marginBottom?: number;
}

interface Props {
  logoStyle?: TLogoStyle;
  backButton?: boolean;
  myInfoButton?: boolean;
  styles?: TStyle;
}

const MainHeader = ({
  logoStyle = 'Default', 
  backButton, 
  myInfoButton,
  styles
}:Props) => {
  const history = useHistory();
  return (
    <Wrapper styles={styles}>
      {backButton && <BackBtnWrap><BackBtn /></BackBtnWrap>}
      <LogoWrapper>
        <Link to="/">
          {logoStyle === 'White' ? <SVG.LogoWSTIWhite /> : <SVG.LogoWSTI />}
        </Link>
      </LogoWrapper>
      {myInfoButton && <MyInfoBtnWrap><MyInfoBtn /></MyInfoBtnWrap>}
    </Wrapper>
  );
};

export default MainHeader;

const Wrapper = styled.header`
  position: relative;

  ${(props:{styles?:TStyle}) => {
    if (props.styles) {
      return css`
        margin-bottom: ${props.styles.marginBottom}px;
      `
    }
  }}
`

const LogoWrapper = styled.div`
  position: relative;
  width: 74px;
  height: 36px;
  margin: 0 auto;
`

const BackBtnWrap = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
`

const MyInfoBtnWrap = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
`
