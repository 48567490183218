import React, { useContext, useEffect, useRef, useState } from 'react';
import { regexName } from 'src/common/util/regex';
import { SignUpContextDispatch } from 'src/common/variables/context';
import useInput from 'src/hook/useInput';
import { SignupContext } from 'src/pages/Signup';
import { Input, Container, Heading } from 'src/style/Signup';
import { InputWrapper, Warning } from 'src/style/Signup/username';

const SignUpUserName = () => {

  const inputRef = useRef<HTMLInputElement>(null);
  const [value, onChange] = useInput('', regexName, inputRef, {blurUpScroll:true});
  const [checkRegex, setCheckRegex] = useState(false);
  const { dispatch } = useContext(SignupContext);

  useEffect(() => {
    inputRef.current?.focus();
  }, [])

  useEffect(() => {
    if (value.match(/[^가-힣]/)) {
      setCheckRegex(false);
      dispatch({type:SignUpContextDispatch.setUserName, payload:''});
    }
    else {
      setCheckRegex(true);
      if (value.length >= 2) {
        dispatch({type:SignUpContextDispatch.setUserName, payload:value});
      } else {
        dispatch({type:SignUpContextDispatch.setUserName, payload:''});
      }
    }
  }, [value, checkRegex])

  // useEffect(() => {
  //   if (!checkRegex) return;
  //   console.log(checkRegex)
  //   if (value.length >= 2) {
  //     dispatch({type:SignUpContextDispatch.setUserName, payload:value});
  //   } else {
  //     dispatch({type:SignUpContextDispatch.setUserName, payload:''});
  //   }
  // }, [value, checkRegex])

  return (
    <Container>
      <Heading>
        <div>안녕하세요!</div>
        <div>성명을 한글로 입력해주세요</div>
      </Heading>
      <InputWrapper length={value.length}>
        <Input
        ref={inputRef}
        type="text"
        value={value}
        onChange={onChange}
        placeholder="내 이름" />
      </InputWrapper>
        {value && !checkRegex && (
          <Warning>
            <div>성명을 올바르게 입력해주세요</div>
            <div>(공백, 영문, 숫자, 특수문자 사용 불가능)</div>
          </Warning>
        )}
    </Container>
  );
};

export default SignUpUserName;
