import { createContext, PropsWithChildren, useEffect, useReducer } from "react";
import { requestGetMember } from "src/common/fetch/fetchs";
import { COOKIE_ACCESS_TOKEN } from "src/common/variables/constant";
import { AuthContextDispatch } from "src/common/variables/context";

interface AuthState{
  signChecked: boolean;
  member?: Member;
}

interface AuthAction {
  type: string;
  payload?: any;
}

const initState:AuthState = {
  signChecked: false,
  member: undefined
};

function reducer(state=initState, action:AuthAction) {
  switch(action.type) {
    case AuthContextDispatch.Init: {
      return initState;
    }
    case AuthContextDispatch.onAuth: {
      return {
        signChecked: true,
        member: action.payload as Member
      }
    }
    case AuthContextDispatch.updateAuth: {
      return {
        signChecked: true,
        member: action.payload as Member
      }
    }
    case AuthContextDispatch.offAuth: {
      return {
        signChecked: true,
        member: undefined
      }
    }

    default:
      return state;
  }
}

export const AuthContext = createContext<{
  state: AuthState,
  dispatch: React.Dispatch<AuthAction>,
  setAuth: (token:string, cb?:()=>void) => void,
  setLogout: () => void;
}>({
  state: initState,
  dispatch: () => null,
  setAuth: (token:string) => false,
  setLogout: () => null
})

export default function AuthProvider({children}:PropsWithChildren<{}>) {
  
  const [state, dispatch] = useReducer(reducer, initState);

  const setAuth = async(accessToken:string, cb?:()=>void) => {
    setCookieValue(COOKIE_ACCESS_TOKEN, accessToken);

    const member = await requestGetMember();
    if (member.error || !member.data) return;

    dispatch({type:AuthContextDispatch.onAuth, payload:member.data.data});
    
    if (cb) cb();
  }

  const setLogout = () => {
    initCookieValue(COOKIE_ACCESS_TOKEN);
    window.location.reload();
  }

  const initSign = async() => {
    const token = getCookieValue(COOKIE_ACCESS_TOKEN);

    if (token) {
      const result = await requestGetMember();
      if (result.error || !result.data) {
        dispatch({type:AuthContextDispatch.offAuth});
        return;
      }
      dispatch({type:AuthContextDispatch.onAuth, payload:result.data.data});
    } 
    else {
      dispatch({type:AuthContextDispatch.offAuth});
    }
  }

  const setWebViewSignInWithToken = () => {
    const webViewEventKey = 'wsti/webviewsigninwithtoken';

    function onWebViewSignInWithToken(e:any) {
      console.log('try signing webview');
      initCookieValue(COOKIE_ACCESS_TOKEN);
      setAuth(e.detail.token);
    }

    window.addEventListener(webViewEventKey, onWebViewSignInWithToken)
  }

  useEffect(() => {
    initSign();
    setWebViewSignInWithToken();
    // (document as any).webViewSignInWithToken('accessToken');
  }, [])
  
  return (
    <AuthContext.Provider 
    value={{
      state, dispatch, setAuth, setLogout
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export const getCookieValue = (key:string) => {
  const result = document.cookie.split(';')
  .map(v => v.trim().split('='))
  .filter(v => v[0] === key)

  return result.length ? result[0][1] : undefined;
}

export const setCookieValue = (key:string, value:string, maxAge=60*60*4) => {
  document.cookie = `${key}=${value}; path=/; max-age=${maxAge};`;
}

export const initCookieValue = (key:string) => {
  document.cookie = `${key}=; path=/; max-age=0`;
}
