import { ReactComponent as LogoWSTI } from 'src/assets/images/logo/logoWSTI.svg';
import { ReactComponent as LogoWSTIWhite } from 'src/assets/images/logo/logoWSTIWhite.svg';
import { ReactComponent as Age10 } from 'src/assets/images/common/age10.svg';
import { ReactComponent as Age20 } from 'src/assets/images/common/age20.svg';
import { ReactComponent as Age30 } from 'src/assets/images/common/age30.svg';
import { ReactComponent as Age40 } from 'src/assets/images/common/age40.svg';
import { ReactComponent as LeftWhite } from 'src/assets/images/icon/arrow/leftWhite.svg';
import { ReactComponent as RightArrowBlack } from 'src/assets/images/icon/arrow/rightArrowBlack.svg';
import { ReactComponent as RightArrowGrey } from 'src/assets/images/icon/arrow/rightArrowGrey.svg';
import { ReactComponent as Korea } from 'src/assets/images/icon/korea.svg';
import { ReactComponent as Pencil } from 'src/assets/images/icon/pencil.svg';
import { ReactComponent as LogoWITH } from 'src/assets/images/logo/logoWITH.svg';
import { ReactComponent as WikenCoin } from 'src/assets/images/icon/wikenCoin.svg';
import { ReactComponent as MyInfo } from 'src/assets/images/icon/myinfo.svg';
import { ReactComponent as Close } from 'src/assets/images/icon/close.svg';
import { ReactComponent as Text404 } from 'src/assets/images/common/404text.svg';

export default {
  LogoWSTI,
  LogoWSTIWhite,
  Age10,
  Age20,
  Age30,
  Age40,
  LeftWhite,
  RightArrowBlack,
  RightArrowGrey,
  Korea,
  Pencil,
  LogoWITH,
  WikenCoin,
  MyInfo,
  Close,
  Text404,
} 