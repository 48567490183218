import styled from "styled-components";

export const InputsWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  margin-bottom: 11px;
  position: relative;
`

export const InputWrapper1 = styled.div`
  position: relative;
  width: 237px;
  height: 44px;
`

export const InputWrapper2 = styled.div`
  position: relative;
  width: 44px;
  height: 44px;
  & input {
    text-align: center;
  }
`

export const Input = styled.input`
  position: relative;
  width: 100%;
  height: 100%;
  background: #222222;
  border: 1px solid #484848;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #FFFFFF;
  outline: none;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.phoneNumber {
    /* padding-left: 37px; */
    text-align: center;
  }
`

export const CountryCode = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  width: 50px;
  height: 34px;
  display: flex;
  column-gap: 2px;
  background: #484848;
  border: 1px solid #484848;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`

export const CountryImg = styled.div`
  position: relative;
  width: 13px;
  height: 31px;
`

export const CountryCodeNumber = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 30px;
  color: #FFF;
`

export const Timer = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  color: #FFF;
  margin-bottom: 4px;
`

export const ReValidateButton = styled.div`
  outline: none;
  border: none;
  background: transparent;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  color: #4E4E4E;
  cursor: pointer;
`
