import React from 'react';
import { ICategoryInfo } from 'src/type/types';
import styled from 'styled-components';

export default function CategoryInfo({...data}:ICategoryInfo){
  return (
    <>
      <Header>
        <h2>{data.category}</h2>
        <p>" {data.explain} "</p>
      </Header>
      <Info>
        {data.info.split('\n').map((text, idx) => (
          text ? (
            <p key={idx}>{text}</p>
          ) : <br key={idx}/>
        ))}
      </Info>
    </>
  )
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
  margin-bottom: 24px;
  border-bottom: 1.5px solid #653789;

  & > h2 {
    font-family: 'NanumSquareNeoHeavy';
    font-weight: 900;
    font-size: 25px;
    margin-bottom: 13px;
    line-height: 30px;
    color: #653789;
  }

  & > p {
    font-family: 'NanumSquareNeoExtraBold';
    font-weight: 800;
    font-size: 13px;
    line-height: 30px;
    color: #653789;
  }
`

const Info = styled.div`
  padding-left: 10px;

  & > p {
    font-family: 'NanumSquareNeoBold';
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: #333;
  }
`