import React, { useState, useCallback, useRef, useMemo } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { IMAGES } from 'src/common/variables/images';
import { colors } from 'src/style/styled';
import { useParams } from 'react-router-dom';

interface Props {
  hashPath: string;
}

export default function ShareList({hashPath}:Props) {

  const params:{hashPath:string} = useParams();

  const shareLink = useMemo(() => {
    return params.hashPath ? window.location.href : window.location.href + '/' + hashPath
  }, [params, hashPath]);

  const clipboardRef = useRef<HTMLDivElement|null>(null);

  const shareKaKao = useCallback(() => {
    const kakao = (window as any).Kakao;
    
    if(!kakao) {
      console.error('Not load kakao sdk');
      return;
    }

    if(!kakao.isInitialized()) {
      kakao.init(process.env.REACT_APP_KAKAO_API);
    }

    if(!kakao.isInitialized()) {
      console.log('Not initialized kakao sdk');
      return;
    }

    kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: 'WSTI(WITH Sports Type Indicator)',
        description: '나의 스포츠 유형 테스트',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/withweb-emotion-77c4f.appspot.com/o/WSTI_image.png?alt=media&token=d33efd04-139a-48e3-a255-fd11c19195ab',
        link: {
          mobileWebUrl: shareLink,
          webUrl: shareLink,
        },
      },
    })
  }, []);

  const shareMeta = useCallback(() => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`);
  }, []);
  
  const shareTwitter = useCallback(() => {
    window.open(`https://twitter.com/intent/tweet?text=스포츠 성향 테스트하기&url=${shareLink}`)
  }, []);

  const shareTelegram = useCallback(() => {
    window.open(`https://t.me/share/url?url=${shareLink}&text=projectWITH_SPORT_MBTI`)
  }, []);

  const [viewClipboard, setViewClipboard] = useState(false)
  const copyClipboard = useCallback(() => {
    navigator.clipboard.writeText(shareLink)
    .then(() => {
      if(!viewClipboard && clipboardRef) {
        setViewClipboard(true);
        setTimeout(() => {
          setViewClipboard(false);
        }, 1000);
      }
    })
    .catch(error => {
      console.error('clipboard 로 복사 중 에러');
    })
  }, [viewClipboard, clipboardRef]);

  return (
    <Wrapper>
      <li onClick={shareKaKao}>
        <img src={IMAGES.icon.share.KAKAO.src} alt="kakao로 테스트 공유하기" />
      </li>
      <li onClick={shareMeta}>
        <img src={IMAGES.icon.share.META.src} alt="meta(facebook)로 테스트 공유하기" />
      </li>
      <li onClick={shareTelegram}>
        <img src={IMAGES.icon.share.TELEGRRAM.src} alt="telegram로 테스트 공유하기" />
      </li>
      <li onClick={shareTwitter}>
        <img src={IMAGES.icon.share.TWITTER.src} alt="twitter로 테스트 공유하기" />
      </li>
      <li onClick={copyClipboard}>
        <img src={IMAGES.icon.share.LINK.src} alt="link로 테스트 공유하기" />
        <Clipboard ref={clipboardRef} view={viewClipboard}>copy!</Clipboard>
      </li>
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;

  & > li {
    cursor: pointer;
    position: relative;
  }

  & img {
    width: 48px;
    height: 49px;
  }
`

const FadeClipText = keyframes`
  from {
    color: ${colors.purple};
  }
  to {
    color: transparent;
  }
`

const Clipboard = styled.div`
  position: absolute;
  font-family: 'Noto Sans';
  top: -22px;
  left: 4px;
  color: transparent;
  font-weight: bold;
  will-change: color;
  ${(props:{view:boolean}) => {
    if (props.view) {
      return css`
        animation: ${FadeClipText} 1s;
      `
    } else {
      return css`
        animation: none;
      `
    }
  }}
`
