export const AuthContextDispatch = {
  Init:'init',
  onAuth:'onAuth',
  offAuth:'offAuth',
  updateAuth:'updateAuth'
}

export const SignUpContextDispatch = {
  setUserName:'setUserName',
  setBirth:'setBirth',
  setPhoneNumber:'setPhoneNumber',
  setPhoneNumberValidate:'setPhoneNumberValidate',
  setGender:'setGender',
  setAge:'setAge',
  setNextStep:'setNextStep',
}

export const SignInContextDispatch = {
  setPhoneNumber:'setPhoneNumber',
  setPhoneNumberValidate:'setPhoneNumberValidate',
  setNextStep:'setNextStep'
}

export const GlobalPopupContextDispatch = {
  openPopup:'openPopup',
  closePopup:'closePopup'
}