import { IUserSelectedSurveyTest } from "src/type/types";
import fetcher from "./fetch"

export const requestPhoneCert = 
({phoneNumber}:{phoneNumber:string}) => {
  return fetcher("PUT", "auth/cert", {phoneNumber});
}

export const requestVerifyPhoneCert = 
<T>({phoneNumber, certNumber}:{phoneNumber:string, certNumber:string}) => {
  return fetcher<T>("POST", "auth/verify", {phoneNumber, certNumber})
}

export const requestSignUp = 
(signupBody:{realName:string, birthDay:string, gender:"MALE"|"FEMALE", phoneNumber:string}) => {
  return fetcher<ResponseSignUp>("POST", "member/sign-up", signupBody);
}

export const requestGetMember = () => {
  return fetcher<{data:Member}>("GET", "member");
}

export const requestStartCheck = () => {
  return fetcher("GET", "wsti/start-check");
}

export const requestSubmitTest = 
(data:{surveyData:IUserSelectedSurveyTest[], userAgent:string}) => {
  return fetcher<{data:string}>("POST", "wsti", data);
}

export const requestGetResult = (hashPath?:string) => {
  return fetcher<{data:WSTIResult}>('GET', `wsti${hashPath ? `/arrival/${hashPath}` : ''}`);
}

export const requestFullVersionUnlock = () => {
  return fetcher<{data:WSTIResult}>('PATCH', 'wsti/unlock');
}

export const requestVerifyIsPlayer = (code:string) => {
  return fetcher('POST', 'wsti/player', {code});
}
