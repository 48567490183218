import styled from 'styled-components';

export const Background = styled.div`
  background: #0F0F0F;
`

export const Wrapper = styled.div`
  max-width: 393px;
  margin: 0 auto;
  min-height: calc(100vh - 47px - 47px - 9px);
  padding-top: 20px;

  & > :nth-child(2) {
    margin: 0 29px 28px;
  }
`

export const Title = styled.h1`
  font-family: 'NanumSquareNeoHeavy';
  font-size: 27px;
  line-height: 30px;
  color: #FFF;
  text-align: center;
  margin-bottom: 21px;
`

export const HeaderWrapper = styled.header`
  padding: 45px 0 0 19px;
  display: flex;
  
  & :first-child{
    margin-right: 22px;
  }
`

export const Intro = styled.p`
  font-family: 'NanumSquareNeoBold';
  font-size: 12px;
  line-height: 21px;
  letter-spacing: -0.6px;
  margin-bottom: 40px;
  padding: 0 30px;
  color: #FFF;
`

export const CategoryCards = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin: 0 auto 45px;
  padding: 0 calc((393px - 359px) / 2);

  & > li {
    margin-bottom: 15.75px;
  }
`

export const RoutesWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`

export const ButtonWrapper = styled.div`
  position: relative;
  width: 345px;
  height: 48px;
`
