import styled, { css } from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(32, 32, 32, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Popup = styled.div`
  background: #000000;
  border-radius: 20px;
  width: 345px;
  padding: 24px 18px;
`

export const Title = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #FFF;
  padding: 0 16px;

  ${(props:{styles?:{titleTextAlign?:string}}) => {
    if (props.styles?.titleTextAlign === 'center') {
      return css`
        text-align: center;
      `
    }
  }}
`

export const Info = styled.ul`
  margin-bottom: 16px;
  li {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    color: #7C7C7C;
  }
`

export const Button = styled.button`
  position: relative;
  outline: none;
  border: none;
  background: #FFFFFF;
  border-radius: 14px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  width: 100%;
  height: 48px;
  bottom: 0;
`

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`

export const TransactionRecord = styled.div`
  margin-top: 14px;
  text-align: center;
  font-family: 'NanumSquareNeoExtraBold';
  font-size: 12px;
  line-height: 30px;
  color: #484848;
  text-decoration: underline;
  & > span {
    cursor: pointer;
  }
`
