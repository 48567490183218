import axios from 'axios';
import { getCookieValue } from 'src/component/context/auth';
import { COOKIE_ACCESS_TOKEN } from '../variables/constant';
import { CustomError } from './error';

// ----- axios setting -----
// const HOST = 'https://wedid-server-dev-4kwu3tip2a-du.a.run.app/api/v1';
const HOST =
  process.env.NODE_ENV === 'development' ?
  'https://wedid-server-dev-4kwu3tip2a-du.a.run.app/api/v1' :
  'https://wedid-api.projectwith.com/api/v1';

const Axios = axios.create({
  baseURL: HOST,
  timeout: 10000,
  headers: {
    'Content-Type':'application/json'
  }
})
Axios.interceptors.request.use(function(config){
  const accessToken = getCookieValue(COOKIE_ACCESS_TOKEN);
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
})

// ----- fetch method switch -----

function fetchSwitch<T>(methodType:MethodType, requestUrl:string, data?:any) {
  switch (methodType) {
    case 'GET' : {
      return Axios.get<T>(requestUrl);
    }
    case 'POST' : {
      return Axios.post<T>(requestUrl, JSON.stringify(data))
    }
    case 'PUT' : {
      return Axios.put<T>(requestUrl, JSON.stringify(data))
    }
    case 'PATCH' : {
      return Axios.patch<T>(requestUrl, JSON.stringify(data))
    }
  }
}

// ----- fetch default func -----

export default async function fetcher
<T>(methodType:MethodType, path:string, data?:any):
Promise<FetchProp<T>> {
  try {
    const response = await fetchSwitch(methodType, `${HOST}/${path}`, data);
    return {
      data: response?.data as T,
      error: undefined
    }
  }
  catch (error:any) {
    
    if (error?.response?.status == 409) {
      return {
        data: undefined,
        error: new CustomError(error)
      }
    }
    return {
      data: undefined,
      error
    }
  }
}
