import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
const button = require('src/assets/images/icon/arrow/left_black.png');

export interface IBackBtn {
  anotherHandler?: () => void;
  color?: string;
}

export default function BackBtn({
  anotherHandler, 
  color = '#000'
}:IBackBtn){
  
  const history = useHistory();

  const handleClick = () => {
    if(anotherHandler) {
      anotherHandler();
    } else {
      history.goBack();
    }
  }

  return (
    <ButtonOutlined onClick={handleClick} color={color}>
      <ButtonInlined color={color}>
        <img src={button} alt="클릭시 이전 페이지로 이동합니다"/>
      </ButtonInlined>
    </ButtonOutlined>
  )
}

export const ButtonOutlined = styled.button`
  position: relative;
  width: 30px;
  height: 32px;
  border-radius: 6px;
  background-color: ${(props:{color:string}) => props.color ? props.color : '#653789'};
  outline: none;
  border: none;
  cursor: pointer;
`

export const ButtonInlined = styled.div`
  width: 30px;
  height: 30px;
  border: 1.5px solid ${(props:{color:string}) => props.color ? props.color : '#653789'};
  box-sizing: border-box;
  border-radius: 6px;
  position: absolute;
  left: 0px;
  bottom: 2px;
  background-color: #fff;

  & > img {
    width: 6px;
    height: 9px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
