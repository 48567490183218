import styled from 'styled-components';

export const Background = styled.div`
  background: linear-gradient(199.29deg, #FE5E94 7.65%, #AE73D6 78.61%);
`

export const HeaderWrapper = styled.header`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  width: 100%;
`

export const Main = styled.main`
  max-width: 393px;
  min-height: calc(100vh - 47px - 47px - 9px);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SurveyList = styled.ul`
  margin: 0 auto 36px;
  list-style: none;
  padding: 3px 0;
  height: calc(100vh - 20px - 30px - 20px - 5px - 18px - 36px - 48px - 47px);
  min-height: calc(125px * 3);
  overflow-y: scroll;
  
  ::-webkit-scrollbar {
    display: none;
  }
`

export const ButtonWrapper = styled.div`
  position: relative;
  width: 345px;
  height: 48px;
`

export const VerificationInput = styled.input`
  background: #222222;
  border: 1px solid #484848;
  border-radius: 8px;
  width: 237px;
  height: 44px;
  font-size: 18px;
  font-family: 'NanumSquareNeoBold';
  color: #FFF;
  line-height: 30px;
  text-align: center;
  outline: none;
  margin-bottom: 12px;
`
