import React from 'react';
import styled from 'styled-components';

export default function SurveyProgessBar({current, full}:{current:number, full:number}){

  return (
    <Progress value={current} max={full} />
  )
}

const Progress = styled.progress`
  margin: 0 auto 18px;
  border: 1.58691px solid #040000;
  background-color: #fff;
  width: 322px;
  height: 5px;
  border-radius: 2px;

  -webkit-appearance: none;
  -moz-appearance: none;
  &::-webkit-progress-bar {
    background-color: #fff;
  }
  &::-webkit-progress-value {
    background-color: #AE73D6;
    transition: 0.7s;
    border-right: 1.58691px solid #000;
  }
`