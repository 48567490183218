import { surveysData } from "src/common/variables/dataset";
import { ICurrentSurveyData, IUserSelectedSurveyTemp } from "src/type/types";

export default function nextStepSurvey({
  currentSurveyData,
  lastPage,
  setSplash,
  setIsFinishSurvey,
  setCurrentSurveyData,
  handleScroll,
  ulRef,
  userSelectedTempList
}:{
  currentSurveyData:ICurrentSurveyData,
  lastPage:React.MutableRefObject<number>,
  setSplash:React.Dispatch<React.SetStateAction<boolean>>,
  setIsFinishSurvey:React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentSurveyData:React.Dispatch<React.SetStateAction<ICurrentSurveyData>>,
  handleScroll:(questionSeq:number) => void,
  ulRef:React.RefObject<HTMLUListElement>,
  userSelectedTempList:IUserSelectedSurveyTemp
}) {
  let isFinishCurrentPage = true;
  let notProgressedSeq = 0;
  let s = currentSurveyData.page * 10 - 9;
  let e = currentSurveyData.page * 10;
  e = e > surveysData.length ? surveysData.length : e;

  for(let i = s; i <= e; i++) {
    if (!userSelectedTempList[i]) {
      isFinishCurrentPage = false;
      notProgressedSeq = i;
      break;
    }
  }

  if (isFinishCurrentPage) {
    const nextPage = currentSurveyData.page+1;
    if (nextPage > lastPage.current) {
      setSplash(true)
      setIsFinishSurvey(true);
    }
    else {
      if (nextPage === 4 || 
        nextPage === 6 || 
        nextPage === 7 ) {
        setSplash(true);
      }
      const isMobile = !!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      if (isMobile) {
        ulRef.current?.scrollTo({top:0});
      } else {
        // ulRef.current?.scrollTo({top:0, behavior: 'smooth'})
        requestAnimationFrame(() => ulRef.current?.scrollTo({top:0, behavior: 'smooth'}))
      }
      window.scrollTo({top:0});

      setCurrentSurveyData((state) => {
        return {
          ...state, 
          page: nextPage, 
          data: surveysData.slice(nextPage*10-10, nextPage*10)
        };
      })
    }
  } else {
    handleScroll(notProgressedSeq);
  }
}
