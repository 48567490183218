import styled from "styled-components";

export const Title = styled.h1`
  font-family: 'NanumSquareNeoHeavy';
  font-weight:900;
  font-size:22px;
  line-height:30px;
  color:#FFF;
  margin: 2px 0 27px;
  text-align: center;
`

export const Logout = styled.div`
  position: absolute;
  top: 30px;
  right: 27px;
  font-family: 'NanumSquareNeoExtraBold';
  font-size: 13px;
  color: #FFF;
  line-height: 14px;
  border-bottom: 1px solid #FFF;
  cursor: pointer;
`

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CardWrap = styled.div`
  margin-bottom: 25px;
`

export const Info = styled.div`
  padding: 16px 10px 12px;
  border-bottom: 3px dashed rgba(255, 255, 255, 0.5);
  width: 287px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'NanumSquareNeoExtraBold';
  color: #FFF;

  & > :first-child {
    font-size: 15px;
  }
  & > :last-child {
    font-size: 18px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &:nth-of-type(4) {
    & > :last-child {
      font-weight: 900;
      font-family: 'NanumSquareNeoHeavy';
      letter-spacing: -0.4px;
    }
  }
`

export const Wiken = styled.div`
  position: relative;
  width: 21px;
  height: 21px;
`