import styled from 'styled-components';

export const Background = styled.div`
  position: relative;
  background: #0F0F0F;
  &::before {
    position: absolute;
    width: 100%;
    height: calc(721px - 53px);
    content:'';
    background: linear-gradient(199.29deg, #FE5E94 7.65%, #AE73D6 78.61%);
  }
`

export const Wrapper = styled.main`
  position: relative;
  max-width: 393px;
  margin: 0 auto;
  padding-top: 20px;
  min-height: calc(100vh - 47px - 47px - 9px);
`

export const ImageWrap = styled.div`
  position: relative;
  width: 337px;
  height: 419px;
  margin: 0 auto;
`

export const BackBtnWrap = styled.div`
  position: absolute;
  left: 18px;
  top: 22px;
`

export const Logo = styled.div`
  position: relative;
  width: 74px;
  height: 36px;
  left: 50%;
  transform: translateX(-50%);
`

export const MyInfoBtnWrap = styled.div`
  position: absolute;
  right: 18px;
  top: 22px;
`

export const FreeSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CategoryTitle = styled.h1`
  font-family: 'NanumSquareNeoHeavy';
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 22px;
  text-align: center;
`

export const Title = styled.div`
  margin-bottom: 34px;
  z-index: 1;
  text-align: center;

  & > :nth-child(1) {
    font-family: 'NanumSquareNeoBold';
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  & > :nth-child(2) {
    font-family: 'NanumSquareNeoHeavy';
    font-weight: 900;
    font-size: 30px;
  }
`

export const CardWrapper = styled.div`
  margin-bottom: 37px;
`

export const PaidSection = styled.section`
  
`

export const SportCharacter = styled.div`
  position: relative;

  .title {
    font-family: 'NanumSquareNeoHeavy';
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #FFF;
    text-align: center;
  }

`

export const ChartWrapper = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto 30px;
`

export const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`

export const AddMoreButtonWrapper = styled.div`
  position: relative;
  width: 316px;
  height: 57px;
  margin-bottom: 71px;
`

export const FloatingButton = styled.div`
  position: fixed;
  right: 35px;
  bottom: 35px;
  width: 55px;
  height: 55px;
  cursor: pointer;
  z-index: 9999;
`
