import React, { useContext, useEffect, useState } from 'react';
import SVG from 'src/assets/images/svg/SVG';
import { requestPhoneCert } from 'src/common/fetch/fetchs';
import { SigninContext, SignInStep } from 'src/pages/SignIn';
import { NextStep } from 'src/style/Signup';

const SignInNextStep = () => {

  const { state:SignState, dispatch:SignDispatch } = useContext(SigninContext);

  const [nextStep, setNextStep] = useState(false);

  const phoneCert = async(phoneNumber:string) => {
    setNextStep(false);
    const response = await requestPhoneCert({phoneNumber});
    setNextStep(true);

    if (response.error) {
      console.error('로그인 인증번호 요청 에러');
      return false;
    }
    return true;
  }

  const onNextStep = async() => {
    if (!nextStep) return;

    if (SignState.step === SignInStep.PHONENUMBER) {
      const result = await phoneCert(SignState.phoneNumber);
      if (!result) {
        alert('인증번호 요청 중 문제가 발생했습니다.');
        return;
      }
    }

    SignDispatch({type:'setNextStep'});
  }

  useEffect(() => {
    if (SignState.step === SignInStep.PHONENUMBER) {
      if (SignState.phoneNumber) setNextStep(true);
      else setNextStep(false);
    } else if (SignState.step === SignInStep.PHONENUMBER_AUTHENTICATE) {
      if (SignState.phoneNumberValidate) setNextStep(true);
      else setNextStep(false);
    }
  }, [SignState])

  if (SignState.step === SignInStep.PHONENUMBER_AUTHENTICATE) return <></>;

  return (
    <NextStep
    onClick={onNextStep}
    disabled={!nextStep}>
      <div>
        {nextStep ? <SVG.RightArrowBlack /> : <SVG.RightArrowGrey />}
      </div>
    </NextStep>
  );
};

export default SignInNextStep;