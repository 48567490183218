import styled, { css } from "styled-components";
import { colors } from "../styled";

export const Container = styled.div`
  background: ${colors.black};
`

export const Main = styled.main`
  margin: 0 auto;
  max-width: 393px;
  min-height: calc(100vh - 47px - 47px - 9px);
  padding-top: 20px;
`

export const Header = styled.div`
  padding: 20px 0;
  position: relative;
  margin-bottom: 64px;
  &.sign {
    margin-bottom: 21px;
  }
`

export const ListWrapper = styled.div`
  position: relative;
  margin: 0 auto 55px;
  max-width: 290px;
  /* overflow: hidden; */

  &.signIn {
    margin: 0 auto 25px;
  }

  & .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0;
    &.slick-active > :first-child {
      background: #FFF !important;
    }
  }
  &.signIn .slick-dots ul {
    bottom: -70px !important;
  }
`

export const ImageWrapper = styled.li`
  position: relative;
  width: 100%;
  height: 100%;
`

export const IntroTitle = styled.h1`
  margin: 0;
  margin-bottom: 104px;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  color: #FFF;
  text-align: center;
`

export const Greet = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #FFF;
  text-align: center;
  margin-bottom: 83px;
`

export const ButtonWrapper = styled.div`
  text-align: center;
  max-width: 345px;
  height: 48px;
  margin: 0 auto 8px;
  display: flex;
  column-gap: 11px;
`

export const Button = styled.button`
  outline: none;
  border: none;
  border-radius: 14px;
  background: #FFF;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  max-width: 345px;
  width: 100%;
  height: 48px;
  cursor: pointer;
`

export const Explain = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  color: #7C7C7C;

  & a {
    color: inherit;
    /* text-decoration: underline; */
    padding-bottom: 2px;
    border-bottom: 1px solid #7C7C7C;
  }
`

// signing

export const Heading = styled.div`
  color: #FFF;
  margin-bottom: 18px;
  text-align: center;
  
  & > :nth-child(1) {
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 7px;
  }
  & > :nth-child(2) {
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
  }
`
