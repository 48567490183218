import React, { useContext, useEffect, useRef } from 'react';
import { inputKeyDownNumberType, regexDay, regexMonth, regexYear } from 'src/common/util/regex';
import { SignUpContextDispatch } from 'src/common/variables/context';
import useInput from 'src/hook/useInput';
import { SignupContext } from 'src/pages/Signup';
import { Input, Container, Heading } from 'src/style/Signup';
import { Example, InputsWrapper, InputWrapper } from 'src/style/Signup/birth';

const SignUpBirth = () => {

  const { state, dispatch } = useContext(SignupContext);

  const yearRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);

  const [year, setYear] = useInput('', regexYear);
  const [month, setMonth] = useInput('', regexMonth);
  const [day, setDay] = useInput('', regexDay, dayRef, {blurUpScroll:true});

  useEffect(() => {
    yearRef.current?.focus();
  }, [])

  useEffect(() => {
    if (yearRef.current && monthRef.current && dayRef.current) {
      const activeEl = document.activeElement;
      if (activeEl === yearRef.current && year.length == 4) monthRef.current.focus();
      if (activeEl === monthRef.current && month.length == 2) dayRef.current.focus();
    }
  }, [year, month])

  useEffect(() => {
    if (
      year.length == 4 && 
      (month.length == 2 || month.length == 1) && 
      (day.length == 2 || day.length == 1)
    ) {

      if (month == '0' || day == '0') return;

      let y = year, m = month, d = day;
      if (m.length == 1) m = '0' + m;
      if (d.length == 1) d = '0' + d;
      dispatch({type:SignUpContextDispatch.setBirth, payload:[y,m,d].join('/')});
    } else {
      dispatch({type:SignUpContextDispatch.setBirth, payload:''});
    }
  }, [year, month, day])

  return (
    <Container>
      <Heading>
        <div>{state.username} 님,</div>
        <div>생일은 언제인가요?</div>
      </Heading>
      <InputsWrapper>
        <InputWrapper length={year.length}>
          <Input
          type="number"
          onKeyPress={inputKeyDownNumberType}
          ref={yearRef}
          value={year}
          onChange={setYear}
          placeholder="년" />
        </InputWrapper>
        <InputWrapper>
          <Input
          type="number"
          onKeyPress={inputKeyDownNumberType}
          ref={monthRef}
          value={month}
          onChange={setMonth}
          placeholder="월" />
        </InputWrapper>
        <InputWrapper>
          <Input
          type="number"
          onKeyPress={inputKeyDownNumberType}
          ref={dayRef}
          value={day}
          onChange={setDay}
          placeholder="일" />
        </InputWrapper>
      </InputsWrapper>
      <Example>예) 2023 / 01 / 01</Example>
    </Container>
  );
};

export default SignUpBirth;