import React from 'react';
import styled from 'styled-components';

const Loading = ({size}:{size?:number}) => {
  return (
    <Wrapper className="loading">
      <Spin size={size}/>
    </Wrapper>
  )
};

export default Loading;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Spin = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &,
  &:after {
    border-radius: 50%;
    width: ${(props:{size?:number}) => props.size ? props.size * 20 + 'px' : '20px'};
    height: ${(props:{size?:number}) => props.size ? props.size * 20 + 'px' : '20px'};
  }
  & {
    font-size: ${(props:{size?:number}) => props.size ? props.size * 2 + 'px' : '2px'};
    position: relative;
    text-indent: -9999em;
    border-top: ${(props:{size?:number}) => props.size ? props.size * 2 + 'px' : '2px'} solid rgba(200, 200, 200, 0.7);
    border-right: ${(props:{size?:number}) => props.size ? props.size * 2 + 'px' : '2px'} solid rgba(200, 200, 200, 0.7);
    border-bottom: ${(props:{size?:number}) => props.size ? props.size * 2 + 'px' : '2px'} solid rgba(200, 200, 200, 0.7);
    border-left: ${(props:{size?:number}) => props.size ? props.size * 2 + 'px' : '2px'} solid #FFF;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
