import styled from 'styled-components';
import { colors } from 'src/style/styled';
import {
  Title,
} from 'src/style/category';

export const AbilityWrapper = styled.section`

`

export const ExplainFrame = styled.div`
  max-width: 356px;
  width: 100%;
  margin: 0 auto 60px;
  background: #242424;
  border: 2px solid rgba(255, 255, 255, 0.04);
  border-radius: 16px;
  padding: 28px 30px;

  & > .heading {
    margin-bottom: 21px;
    text-align: center;
    font-family: 'NanumSquareNeoHeavy';
    font-size: 20px;

    span {
      background: linear-gradient(90deg, #FE5E94 0%, #AE73D6 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
`

export const Explain = styled.li`
  margin-bottom: 14px;
  & > .title {
    color: #FFF;
    font-size: 17px;
    line-height: 30px;
    font-family: 'NanumSquareNeoExtraBold';
    text-align: center;
    padding-bottom: 6px;
    margin: 0 auto 6px;
    position: relative;
    width: max-content;

    &::after {
      content: '';
      width: 100%;
      height: 1.5px;
      background: linear-gradient(90deg, #FE5E94 0%, #AE73D6 100%);
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  & > .info {
    font-size: 12px;
    font-family: 'NanumSquareNeoBold';
    color: #FFF;
    line-height: 24px;
  }
`

export const AbilityHeader = styled.h3`
  font-family: 'NanumSquareNeoHeavy';
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  color: #FFF;
  margin-bottom: 17px;
  text-align: center;
`

export const AbilityList = styled.ul`
  font-family: 'NanumSquareNeoBold';
  font-weight: 700;
  font-size: 13px;
  margin: 0 40px 60px;
  padding: 0;
`

export const AdSection = styled.section`
  position: relative;
  z-index: 1;
`

export const ADHeader = styled.h3`
  font-family: 'NanumSquareNeoHeavy';
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 32px;
  text-align: center;
  color: #FFF;
`

export const CategorySlideWrap = styled.div`
  margin-bottom: 50px;
`

export const ADLink = styled.div`
  position: relative;
  text-align: center;
  cursor: pointer;
`

export const ADBack = styled.div`
  background-color: ${colors.lightSalmon};
  border: 2.2257px solid #000;
  width: 352px;
  height: 275px;
  z-index: -1;
  border-radius: 15px;
  margin: 0 auto 66px;
  position: relative;
  cursor: pointer;

  & > img {
    position: absolute;
    bottom: 5px;
    left: -6px;
    width: 360px;
    height: 270px;
    cursor: pointer;
  }
`

export const ShareWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0;

  .alert {
    font-family: 'NanumSquareNeoBold';
    font-size: 12px;
    line-height: 30px;
    color: #484848;
    margin-bottom: 13px;
    text-align: center;
  }
`

export const ShareHeader = styled.div`
  font-family: 'NanumSquareNeoExtraBold';
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  color: #FE5E94;
  text-align: center;
  margin-bottom: 20px;
`

export const ShareListWrapper = styled.div`
  padding: 0 9px;
  margin-bottom: 8px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 66px;
`

export const ButtonWrapper = styled.div`
  position: relative;
  width: 345px;
  height: 46px;
  margin: 0 auto;
`
