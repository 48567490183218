import { IMAGES } from "src/common/variables/images";
import styled, { css } from "styled-components";
// import BG from 'src/assets/images/common/cardBackground.png';

export const Container = styled.div`
  position: relative;
  width: 283px;
  height: 376px;
  background-image: url(${IMAGES.common.cardBackground});
  background-size: cover;
  background-position: center;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const WSTI = styled.div`
  position: relative;
  width: 62px;
  height: 30px;
  margin-bottom: 3px;
`

export const Player = styled.div`
  position: relative;
  width: 178px;
  height: 221px;
  margin-bottom: 20px;

  ${(props:{styles?:{width:number,height:number}}) => {
    if (props.styles) {
      return css`
        width: ${props.styles.width}px;
        height: ${props.styles.height}px;
      `
    }
  }}
`

export const PlayerType = styled.div`
  font-family: 'NanumSquareNeoHeavy';
  font-weight: 900;
  font-size: 20px;
  letter-spacing: -0.2px;
  color: #FFF;
`

export const Logo = styled.div`
  position: absolute;
  width: 60px;
  height: 10px;
  bottom: 28px;
`

export const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(254, 94, 148, 0.4);
  border-radius: 7px;

  & > :first-child {
    font-family: 'NanumSquareNeoHeavy';
    font-weight: 900;
    font-size: 76px;
    color: #FE5E94;
    position: absolute;
    top: 112px;
    left: 50%;
    transform: translateX(-50%);
  }

  & > :last-child {
    position: absolute;
    bottom: 69px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'NanumSquareNeoExtraBold';
    font-weight: 800;
    font-size: 17px;
    line-height: 19px;
    color: #FE5E94;
  }
`
