import React from 'react';
import SVG from 'src/assets/images/svg/SVG';
import styled from 'styled-components';

export default function Footer(){
  return (
    <Wrapper>
      <LogoWrap>
        <a target="_blank" href="https://main.projectwith.io">
          <SVG.LogoWITH />
        </a>
      </LogoWrap>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  padding: 47px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  max-width: 393px;
  width: 100%;
  margin: 0 auto;
`

const LogoWrap = styled.div`
  position: relative;
  width: calc(59px * 1.2);
  height: calc(9px * 1.2);
`
