// -------------------- 설문조사 데이터 ----------------------

const dataset = [
"나는 대화를 먼저 시작하는 편이다.71",
"나는 조용하기보다는 말을 많이 하는 편이다.71",
"나는 쾌활하고 명랑한 사람이다.71",
"스트레스를 받으면, 다른 사람들과 대화하면서 풀기보다는 조용히 집에 있고 싶다.71",
"나는 오랫동안 집에만 있는 것이 힘들다.71",
"나는 일을 할 때 절차와 순서를 밟아서 체계적으로 처리하는 편이다.66",
"나는 일을 할 때 계획세우는 것을 좋아한다.66",
"나는 내 인생에서 꼭 이루고 싶은 목표가 있다.66",
"매사에 유능하고 뛰어나기 위해 노력한다.66",
"나는 내 주변사람들의 기대에 성공적으로 부응해왔다.66",
"나는 걱정을 많이 하는 편이다.80",
"신경이 예민하고 자주 긴장되곤 한다.80",
"다른 사람들이 나를 대하는 태도 때문에 화가 날 때가 많다(있다).80",
"나는 상황에 따라 감정이 잘 바뀐다.80",
"가끔 내 자신이 전혀 가치가 없는 존재로 느껴진다.80",
"일이 잘못될 때는 다 포기해버리고 싶을 때가 많다.80",
"나는 다른 사람이 매우 부럽다.80",
"좀처럼 외롭거나 우울해지지는 않는다.80",
"나는 변덕이 있는 편이다.80",
"다른 사람들에 대하여 종종 열등감을 느낀다.80",
"나는 사람을 잘 믿는다.68",
"나는 어색한 상황을 부드럽게 만드는 데 재능이 있다.68",
"내가 만나는 모든 사람에게 친절하려고 노력한다.68",
"나는 다른 사람을 돕는 것이 특별히 더 즐겁다.68",
"나는 팀원들 사기를 올려준다고 생각한다.68",
"어떤 상황에서 책임있는 자리를 맡는 것은 즐거운 경험이다.65",
"나는 다른 사람이 주도하는 일을 따라가는 것이 답답하다.65",
"나는 내가 상황을 통제하지 못하면 상당히 불편하다.65",
"내가 속한 집단에서 곧잘 리더 역할을 맡게 된다.65",
"내가 관여된 일에는 내 의견을 반영시키고 싶다.65",
"때로는 뭔가 때려부수거나 욕을 퍼붓고 싶을 때가 있다.52",
"나는 용서하고 잊기보다 복수하고 싶을 때가 있다.52",
"가끔 다른 사람에 대한 험담을 한다.52",
"내가 집안 식구들과 말다툼하는 일은 없다.52",
"나를 돋보이게 만들기 위해서는 남들보다 월등히 좋은 성과를 내야 한다.63",
"나는 불가능이란 없다고 생각한다.63",
"나는 내 삶에서 큰 변화가 끊임없이 나타나길 바란다.63",
"성공하기 위해서는 내가 할 수 있는 모든 방법을 사용할 것이다.63",
"내가 원하는 것을 변화시키거나 바꾸기 위해 주장적이고 단호하게 행동할 수 있다.63",
"나는 아무리 힘든 일이라도 누구보다 오래 버틴다.70",
"나는 내가 시작한 것은 뭐든지 끝낸다.70",
"다른 사람과 경쟁할 때 무엇이라도 물고 늘어져서 끝장을 본다.70",
"상대보다 실력이 부족해도 끝까지 밀어부친다.70",
"나는 달성하는데 몇 개월이 걸리는 일에도 꾸준히 집중할 수 있다.70",
"예상하지 못했던 일이 일어날 때, 나는 대체로 그 일에 신속하게 대응할 수 있다.60",
"상당한 압력 하에서도, 보통 나는 평온을 유지한다.60",
"나는 플레이에 지장을 줄 정도로 긴장하곤 한다.60",
"내 삶에서 중요한 것에 대하여 스스로 선택하거나 결정할 수 있다.60",
"내가 원하는 것을 변화시키거나 바꾸기 위해 주장적이고 단호하게 행동할 수 있다.60",
"나는 대체로 내가 가진 능력에 대해 자신이 있다.85",
"시합을 두려워하지 않는다.85",
"내 자신을 믿고 훈련에 임한다.85",
"정신적 압박을 이겨 낼 자신이 있다.85",
"나는 무엇이든 할 수 있다는 생각을 가지고 있다.85",
"큰 목표를 이루기 위한 것이더라도 소수를 희생해서는 안된다.63",
"나는 일과 개인생활의 균형이 중요하다고 생각한다.63",
"성취보다는 경험이 더 중요하다.63",
"경기 결과가 좋지 않더라도, 이를 통해 팀원 개개인이 성장했다면 의미있는 경험이다.63",
"중요한 것은 경기의 승패보다 팀웍이다.63",
"나는 팀원이 실수 해도 격려한다.71",
"팀 동료들과 지내는 것이 즐겁다.71",
"팀 동료들에게 내가 도움을 줄 수 있는 것이 있다면 기꺼이 한다.71",
"나는 팀에서 발생한 일에 대해 책임감을 느낀다.71",
"내가 돋보이는 것보다 팀의 승리가 중요하다.71",
"나는 리더십이 뛰어나다.1",
"나는 내 자신의 감정이나 일상생활 등을 스스로 잘 조절한다.2",
"나는 정상급의 실력을 가졌다.3",
"나는 강인한 정신력을 가지고 있다.4",
"나는 팀원들과 잘 어울리며 좋은 분위기를 만든다.5",
"발급받은 코드가 있나요?.-1"
]

function makeData(arr:string[]) {
  return arr.map((v, idx) => {
    const value = v.split('.');
    return {
      id: idx+1,
      question: value[0],
      type: Number(value[1])
    }
  })
}

// 셔플

// function shuffle(arr:string[]) {
//   for(let i = arr.length-1; i>0; i--) {
//     let j = Math.floor(Math.random()*(i+1));
//     [arr[i], arr[j]] = [arr[j], arr[i]];
//   }
// }

// shuffle(dataset);

export const surveysData = makeData(dataset);


// 난수 문자열 만들기
const charset = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIZKLMNOPQRSTUVWXYZ'; // 62

export function getRandomString():string {
  let results = '';
  for(let i = 0; i<12; i++) {
    const ridx = Math.floor(Math.random() * charset.length);
    results += charset.substring(ridx, ridx+1);
  }
  return results;
}

// -------------------- 결과 데이터 셋 ----------------------

export const myAbilityTexts = [
  {
    title: '리더쉽',
    info: '리더십은 팀을 이끌고 팀에게 동기부여를 해 줄 수 있는 능력을 말합니다.'
  },
  {
    title: '자기조절',
    info: '자기조절은 자신이 상황이나 자신의 상태를 통제할 수 있다는 생각을 의미합니다. 자기조절이 높은 사람의 경우 자신의 정서를 잘 조절할 수 있는 힘이 강합니다. 도전적 의지가 강한 운동선수가 자기조절 지수가 높게 나오는 경향이 있습니다.'
  },
  {
    title: '정신적 강인함',
    info: '기존 연구에 따르면 정신적인 강인함은 운동선수의 부상과 연관이 있습니다. 따라서 정신적 강인함이 높은 운동 선수는 그렇지 않은 선수에 비해 상해를 입을 확률이 낮습니다.'
  },
  {
    title: '팀웍',
    info: '팀웍은 팀원들과 협동과 연대를 중요하시 하며 팀을 위해 함께 노력하려는 성향을 의미합니다.'
  },
  {
    title: '경기력',
    info: '경기력은 선수들이 경기를 진행해 나가는 능력을 말합니다. 본 연구를 통해 정서적 안정성과 방어성 그리고 나이요인이 경기력에 유의미한 영향을 미치는 것을 확인할 수 있었습니다.'
  },
]

export const mySportCharacterTexts = [
  {
    title: '성실성',
    info: '성실성은 성격 5요인 중 하나로, 목표 성취를 위해 계획적으로 노력하는 성향을 의미합니다, 성실성이 높은 경우 사회적 규칙이나 규범을 잘 따릅니다. 기존 연구에 따르면 선수의 성실성 지수는 선수의 인지된 경기력에 정적인 상관관계를 보이고 있으며, 일부 연구는 실제 게임점수에도 유의미한 예측변인으로 작용한다는 점을 보여주고 있습니다.'
  },
  {
    title: '외향성',
    info: '외향성은 성격 5요인 중 하나로, 타인과의 상호작용과 새로운 작응과 활력을 추구하는 경향을 의미합니다. 기존 연구에 따르면 선수의 외향성 지수는 선수의 인지된 경기력에 정적인 상관관계를 보이고 있습니다.'
  },
  {
    title: '정서적 안정성',
    info: '정서적 안정성은 성격 5요인 중 하나로, 정서적으로 안정되어 있는 정도를 의미하며, 정서적 안정성이 높은 경우 자신에 대해 긍정적이며 비교적으로 일관적인 특징을 보입니다. 지도자 인터뷰를 통해 정서적 안정성이 선수를 분류하기 좋은 지표라는 것을 합의하였으며, 통계적으로도 선수를 분류하기 좋은 지표라는 것을 본 연구에서 통해 입증하였습니다.'
  },
  {
    title: '친화성',
    info: '친화성은 타인과 편안하고 조화로운 관계를 유지하려는 성향을 의미합니다. 공동체적 속성을 나타내는 지표로, 심리학에서 가장 많이 연구되는 성격 5요인을 구성하는 특성입니다.'
  },
  {
    title: '인내와 끈기',
    info: '인내와 끈기는 장기간이 소요되는 일이나 어려움이 있더라도 끝까지 해내는 힘을 의미합니다.'
  },
  {
    title: '도전',
    info: '도전은 포기하지 않고 높은 수준의 성취를 달성하기 위해 노력하는 정도를 의미합니다. 도전이 높은 경우 성취 욕구가 높은 경향을 보입니다.'
  },
]

// -------------------- 타입별 기본 데이터 ----------------------

export type TCategoryDefaultData = {
  category: string;
  tendency: WstiTendency;
  explain: string;
  info: string[]
}

export const CategoryDefaultData:TCategoryDefaultData[] = [
  {
    category: '헌신형',
    tendency: "DEDICATION",
    explain: '묵묵히 노력하고 끝까지 해내고자 하는 도전정신',
    info: [
      "헌신형은 다른 사람 앞에 자신을 드러내거나 자신의 뜻을 강하게 펼치지 않지만 묵묵히 노력하며 팀원을 배려합니다.",
      "당신 안에는 어려움 속에서도 뜻을 굽히지 않고 끝까지 해내고자 하는 도전정신과 그 도전을 계속해나갈 근성이 있습니다.",
      "하지만 때로는 감정기복과 부담감 등으로 인해 가지고 있는 힘이 잘 드러나지 않을 수도 있습니다.",
      "자기 안의 힘이 더 강하게 발휘될 수 있도록 자기 자신의 역량에 자신감을 가져보길 바랍니다.",
    ]
  },
  {
    category: '근성형',
    tendency: 'GUTS',
    explain: '성실함과 자신감으로 목표를 향해 달려가는 플레이어',
    info: [
      "근성형은 특유의 성실함과 자신감으로 묵묵히 자신의 목표를 향해 달려가고 있습니다.",
      "상황이 자신을 이끌도록 내버려두기 보다는 스스로 길을 개척하며 도전을 즐기는 편입니다. 어려움 앞에서도 단단하게 지탱하며 스스로를 조절하는 힘이 누구보다 강합니다.",
      "때로는 사람들과 어울리기보다 자기의 세계에 머무는 것을 선호하고 남들 앞에 나서지 않아 다소 소극적이라는 평가를 받을 수도 있습니다.",
      "필요하다면 자신의 의견과 특성을 조금 더 표현해보는 것도 좋습니다."
    ]
  },
  {
    category: '리더형',
    tendency: 'LEADER',
    explain: '긍정적인 기운을 전달하는 타고난 리더십의 소유자',
    info: [
      "리더형은 스스로도 더 나은 모습이 되기 위해 끊임없이 도전하고 노력하면서 다른 사람과 소통하고 팀을 잘 이끄는 것도 놓치지 않습니다.",
      "사람들은 주변에 긍정적인 기운을 전달하는 당신을 타고난 리더라고 생각할 것입니다.",
      "현재에 머물러 있기 보다는 더 나은 선수가 되기 위해 스스로를 단련하고, 지금 내가 할 수 있는 것은 무엇인지 고민합니다.",
      "때로는 스스로를 너무 몰아붙이기도 하는데 자신만의 휴식, 재충전 방법을 잘 실천하면서 균형을 유지하시길 바랍니다."
    ]
  },
  {
    category: '주도형',
    tendency: "LEAD",
    explain: '적극적인 에너지가 넘치는 자기주도적인 플레이어',
    info: [
      "주도형은 적극적인 에너지가 넘치는 자기주도적인 플레이어입니다.",
      "다른 사람이 시키는 것을 그대로 따르기 보다는 스스로 목표를 정하고, 강한 동기를 가지며 자신의 목표를 달성하는 것에 희열을 느끼곤 합니다.",
      "하지만 훌륭한 선수의 모습을 보여주기 위해 에너지를 온전히 쏟아내다 보면 금방 지치거나 감정 기복을 느끼기도 합니다. 다른 사람이나 새로운 것에 대한 호기심도 있어 집중이 흐트러질 때도 있습니다.",
      "강약을 조절하면서 주도형 특유의 적극적이고 자발적인 힘을 충분히 발휘하실 바랍니다."
    ]
  },
]
