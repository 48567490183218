import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import SVG from 'src/assets/images/svg/SVG';
import { SignInContextDispatch } from 'src/common/variables/context';
import { AuthContext } from 'src/component/context/auth';
import Footer from 'src/component/Footer';
import SignInNextStep from 'src/component/SignIn/SignInNextStep';
import SignInPhoneNumber from 'src/component/SignIn/SignInPhoneNumber';
import SignInPhoneNumberValidate from 'src/component/SignIn/SignInPhoneNumberValidate';
import useMountedUpScroll from 'src/hook/useMountedUpScroll';
import { BackArrow, Background, Contents, Header, Logo, Main } from 'src/style/Signup';

export enum SignInStep {
  PHONENUMBER,
  PHONENUMBER_AUTHENTICATE
}

interface SigninState {
  phoneNumber: string;
  phoneNumberValidate: boolean;
  step: SignInStep;
}

interface SigninAction {
  type: string;
  payload?: any;
}

const initialState:SigninState = {
  phoneNumber: '',
  phoneNumberValidate: false,
  step: SignInStep.PHONENUMBER
}

function reducer(state=initialState, action:SigninAction) {
  switch(action.type) {
    case SignInContextDispatch.setPhoneNumber: {
      return {...state, phoneNumber: action.payload}
    }
    case SignInContextDispatch.setPhoneNumberValidate: {
      return {...state, phoneNumberValidate: action.payload}
    }
    case SignInContextDispatch.setNextStep: {
      return {...state, step: state.step + 1}
    }
    default:
      return state;
  }
}

export const SigninContext = createContext<{
  state: SigninState,
  dispatch: React.Dispatch<SigninAction>
}>({
  state: initialState,
  dispatch: () => null
})

const SignIn = () => {
  useMountedUpScroll();

  const { state:AuthState } = useContext(AuthContext);
  const { state:SignInState } = useContext(SigninContext);
  const history = useHistory();

  useEffect(() => {
    if (AuthState.member) {
      history.push('/');
    }
  }, [AuthState])

  if (!AuthState.signChecked) return <></>;

  return (
    <Background>
      <Main>
        <Header>
          <BackArrow 
          onClick={() => history.goBack()}>
            <SVG.LeftWhite />
          </BackArrow>
          <Logo onClick={() => history.push('/')}>
            <SVG.LogoWSTI />
          </Logo>
        </Header>
        <Contents>
          {
            SignInState.step === SignInStep.PHONENUMBER ? <SignInPhoneNumber /> :
            SignInState.step === SignInStep.PHONENUMBER_AUTHENTICATE ? <SignInPhoneNumberValidate /> :
            ''
          }
        </Contents>
        <SignInNextStep />
      </Main>
      <Footer />
    </Background>
  );
};

export default function SigninProvider() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <SigninContext.Provider value={{state, dispatch}}>
      <SignIn />
    </SigninContext.Provider>
  )
}

