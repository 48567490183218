import React from 'react';
import styled from 'styled-components';
import { ICategoryByAgeGroup } from 'src/type/types';
import CategoryCard from 'src/component/CategoryCard';
import Slider from 'react-slick';
import { CategoryDefaultData } from 'src/common/variables/dataset';

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 1500,
  pauseOnHover: true,
  autoplay: true,
  autoplaySpeed: 3000,
}

function CategorySlide(){
  return (
    <Wrapper>
      <Slider {...settings}>
        {CategoryDefaultData.map(data => (
          <div key={data.tendency}>
            <CategoryCard data={data}/>  
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default CategorySlide;

const Wrapper = styled.div`
  max-width: 393px;
  height: 212px;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-left: calc((393px - 358px) / 2 + 2px);
  &::-webkit-scrollbar {
    display: none;
  }
`
