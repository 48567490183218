import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import checkTransactionHash from 'src/common/util/checkTransactionHash';
import { GlobalPopupContextDispatch } from 'src/common/variables/context';
import { Button, ButtonWrapper, Container, Info, Popup, Title, TransactionRecord } from 'src/style/component/globalPopup';

type GlobalPopupStateType = 'DEFAULT'|'CONFIRM';

interface GlobalPopupState {
  open: boolean;
  title?: React.ReactElement;
  info?: React.ReactElement[];
  popupType: GlobalPopupStateType;
  callback?: () => void;
  callbackText?: string;
  cancleText?: string;
  transaction?: string;
  styles?: {
    titleTextAlign?: string;
  }
  option?: {
    preventAutoClose?: boolean;
  }
}

interface GlobalPopupAction {
  type:string;
  payload?:{
    title?:React.ReactElement;
    info?:React.ReactElement[];
    popupType?:GlobalPopupStateType;
    callback?:() => void;
    callbackText?: string;
    cancleText?: string;
    transaction?: string;
    styles?: {
      titleTextAlign?: string;
    }
    option?: {
      preventAutoClose?: boolean;
    }
  }
}

const initState:GlobalPopupState = {
  open: false,
  popupType: 'DEFAULT'
}

export const GlobalPopupContext = createContext<{
  state: GlobalPopupState,
  dispatch: React.Dispatch<GlobalPopupAction>
}>({
  state: initState,
  dispatch: () => null
})

const reducer = (state=initState, action:GlobalPopupAction) => {
  switch(action.type) {
    case GlobalPopupContextDispatch.openPopup: {
      return {
        open: true,
        popupType: action.payload?.popupType ?? 'DEFAULT',
        title: action.payload?.title,
        info: action.payload?.info,
        callback: action.payload?.callback,
        callbackText: action.payload?.callbackText,
        cancleText: action.payload?.cancleText,
        transaction: action.payload?.transaction,
        styles: action.payload?.styles,
        option: action.payload?.option
      }
    }
    case GlobalPopupContextDispatch.closePopup: {
      return initState
    }
    default:
      return state;
  }
}

const GlobalPopup = () => {
  const {state, dispatch} = useContext(GlobalPopupContext);
  const ref = useRef<HTMLDivElement>(null);

  const close = () => {
    dispatch({type:GlobalPopupContextDispatch.closePopup});
  }
  const closePopup = useCallback((e:MouseEvent) => {
    const { target, currentTarget } = e;
    if (target === currentTarget) {
      close();
    }
  }, [])
  const callback = () => {
    if (state.callback) state.callback();
    if (state.option?.preventAutoClose) return;
    close();
  }

  useEffect(() => {
    ref.current?.addEventListener('click', closePopup);
    return () => ref.current?.removeEventListener('click', closePopup);
  }, [state])

  if (!state.open) return <></>;

  return (
    <Container 
    ref={ref}
    className="globalPopup">
      <Popup>
        <Title styles={state.styles}>{state.title}</Title>
        <Info>
          {state.info?.map((i, idx) => (
            <li key={idx}>{i}</li>
          ))}
        </Info>
        {state.popupType === 'DEFAULT' ? (
          <Button
          onClick={state.callback ? callback : close}
          type="button"
          >확인</Button>
        ) : (
          <ButtonWrapper>
            {/* <Button onClick={callback}>확인</Button> */}
            <Button onClick={callback}>{state.callbackText ?? '확인'}</Button>
            {/* <Button onClick={close}>취소</Button> */}
            <Button onClick={close}>{state.cancleText ?? '취소'}</Button>
          </ButtonWrapper>
        )}
        {!!state.transaction && (
          <TransactionRecord onClick={() => checkTransactionHash(state.transaction as string)}>
            <span>내역 확인하기</span>
          </TransactionRecord>
        )}
      </Popup>
    </Container>
  );
};

const GlobalPopupProvider = ({children}:PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <GlobalPopupContext.Provider value={{state, dispatch}}>
      {children}
      <GlobalPopup />
    </GlobalPopupContext.Provider>
  )
}

export default GlobalPopupProvider;