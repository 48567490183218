import React from 'react';
import {
  Inlined,
  Outlined
} from 'src/style/survey/PageTitle';

export function SurveyPageTitle({current, full}:{current:number, full:number}){

  return (
    <Outlined>
      <Inlined>
        {current} / {full}
      </Inlined>
    </Outlined>
  )
}
