export const colors = {
  blue: '#0090FF',
  aquamarine: '#60E2BF',
  aquamarineSemiLight: 'rgba(96, 226, 191, 0.5)',
  aquamarineLight: '#E6FFF8',
  green: '#30BE9D',
  purple: '#AE73D6',
  salmon: '#F73484',
  lightSalmon: '#FE5E94',
  darkSalmon: '#0C8E6C',
  black: '#0F0F0F',
}
