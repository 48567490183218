import { colors } from 'src/style/styled';
import styled, { css } from 'styled-components';

export const Outlined = styled.li`
  width: 324px;
  min-height: 125px;
  position: relative;
  background-color: ${colors.darkSalmon};
  border: 1.55px solid #000;
  border-radius: 15px;
  margin-bottom: 10px;
  ${(props: {done:boolean}) => {
    if(props.done) {
      return css`opacity: 0.3;`;
    }
  }}
`

export const Inlined = styled.div`
  position: absolute;
  bottom: 3.16px;
  left: -1.5px;
  border: 1.55px solid #040000;
  width: 324px;
  min-height: 121.84px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding-top: 27px;
`

export const Sequence = styled.div`
  position: absolute;
  left: 12px;
  top: 6px;
  font-size: 17px;
  color: #000;
`

export const Question = styled.p`
  font-family: 'NanumSquareNeoExtraBold';
  width: 276px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 56px;
  margin-top: 5px;
  color: #0F0F0F;
`

export const SelectItem = styled.li``;

export const SelectContainer = styled.ul`
  display: flex;
  width: calc(324px - 45.17px - 54.06px);
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  position: absolute;
  bottom: 18px;

  & > :first-child, & > :last-child {
    /* font-family: 'AppleSDGothicNeoEB'; */
  }

  & > :nth-child(n+2):nth-child(-n+6) {
    font-family: 'NanumSquareNeoExtraBold';
    font-weight: 800;
    font-size: 10px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & > :nth-child(2) {
    width: 24px;
    height: 24px;
    &:hover {
      background: linear-gradient(90deg, #FE5E94 0%, #AE73D6 100%);
      border: 2.08321px solid #000;
      color: #fff;
    }
    ${(props:{selected:number|undefined}) => {
      if(props.selected && props.selected === 1) {
        return css`
          background: rgba(254, 94, 148, 0.7);
          border: 2.08321px solid #000;
          color: #fff;
        `
      } else {
        return css`
          background: #fff;
          border: 2.08321px solid #FE5E94;
          color: #9E9E9E;
        `
      }
    }}
  }

  & > :nth-child(3) {
    width: 19.78px;
    height: 19.71px;
    &:hover {
      background: linear-gradient(90deg, #FE5E94 0%, #AE73D6 100%);
      border: 2.08321px solid #000;
      color: #fff;
    }
    ${(props:{selected:number|undefined}) => {
      if(props.selected && props.selected === 2) {
        return css`
          background: rgba(254, 94, 148, 0.7);
          border: 2.08321px solid #000;
          color: #fff;
        `
      } else {
        return css`
          background: #fff;
          border: 2.08321px solid ${colors.lightSalmon};
          color: #9E9E9E;
        `
      }
    }}
  }

  & > :nth-child(4) {
    width: 16.87px;
    height: 16.81px;
    background-color: ${(props: {selected:number|undefined}) => 
      props.selected ? (props.selected === 3 ? '#9E9E9E' : '#fff') : '#fff'};
    border: 1.46422px solid ${(props: {selected:number|undefined}) => 
      props.selected ? (props.selected === 3 ? '#000' : '#9E9E9E') : '#9E9E9E'};
    
    &:hover {
      background: rgba(158, 158, 158, 0.7);
      border: 1.46422px solid #000;
      color: #fff;
    }
    ${(props:{selected:number|undefined}) => {
      if(props.selected && props.selected === 3) {
        return css`
          background: rgba(158, 158, 158, 0.7);
          border: 1.46422px solid #000;
          color: #fff;
        `
      } else {
        return css`
          background: #fff;
          border: 2.08321px solid #9E9E9E;
          color: #9E9E9E;
        `
      }
    }}
  }

  & > :nth-child(5) {
    width: 19.78px;
    height: 19.71px;
    background-color: ${(props: {selected:number|undefined}) => 
      props.selected ? (props.selected === 4 ? colors.green : '#fff') : '#fff'};
    border: 2.08321px solid ${(props: {selected:number|undefined}) => 
      props.selected ? (props.selected === 4 ? '#000' : colors.green) : colors.green};
    
    &:hover {
      background: linear-gradient(90deg, rgba(48, 190, 157, 0.7) 0%, rgba(0, 144, 255, 0.7) 100%);
      border: 2.08321px solid #000;
      color: #fff;
    }
    ${(props:{selected:number|undefined}) => {
      if(props.selected && props.selected === 4) {
        return css`
          background: linear-gradient(90deg, rgba(48, 190, 157, 0.7) 0%, rgba(0, 144, 255, 0.7) 100%);
          border: 2.08321px solid #000;
          color: #fff;
        `
      } else {
        return css`
          background: #fff;
          border: 2.08321px solid #0090FF;
          color: #9E9E9E;
        `
      }
    }}
  }

  & > :nth-child(6) {
    width: 24px;
    height: 24px;
    &:hover {
      background: linear-gradient(90deg, rgba(48, 190, 157, 0.7) 0%, rgba(0, 144, 255, 0.7) 100%);
      border: 2.08321px solid #000;
      color: #fff;
    }
    ${(props:{selected:number|undefined}) => {
      if(props.selected && props.selected === 5) {
        return css`
          background: linear-gradient(90deg, rgba(48, 190, 157, 0.7) 0%, rgba(0, 144, 255, 0.7) 100%);
          border: 2.08321px solid #000;
          color: #fff;
        `
      } else {
        return css`
          background: #fff;
          border: 2.08321px solid #0090FF;
          color: #9E9E9E;
        `
      }
    }}
  }
`

export const SelectConfirmContainer = styled.ul`
  display: flex;
  width: calc(324px - 45.17px - 54.06px);
  align-items: center;
  list-style: none;
  padding: 0;
  position: absolute;
  bottom: 18px;

  & > :nth-child(n+2):nth-child(-n+3) {
    font-family: 'NanumSquareNeoExtraBold';
    font-weight: 800;
    font-size: 10px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & > :nth-child(1) {
    margin-right: 12px;
    min-width: max-content;
  }
  & > :nth-child(2) {
    margin-right: auto;
    width: 24px;
    height: 24px;
    &:hover {
      background: linear-gradient(90deg, #FE5E94 0%, #AE73D6 100%);
      border: 2.08321px solid #000;
      color: #fff;
    }
    ${(props:{selected?:boolean}) => {
      if(props.selected !== undefined && !props.selected) {
        return css`
          background: rgba(254, 94, 148, 0.7);
          border: 2.08321px solid #000;
          color: #fff;
        `
      } else {
        return css`
          background: #fff;
          border: 2.08321px solid #FE5E94;
          color: #9E9E9E;
        `
      }
    }}
  }
  & > :nth-child(3) {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    &:hover {
      background: linear-gradient(90deg, rgba(48, 190, 157, 0.7) 0%, rgba(0, 144, 255, 0.7) 100%);
      border: 2.08321px solid #000;
      color: #fff;
    }
    ${(props:{selected?:boolean}) => {
      if(props.selected !== undefined && props.selected) {
        return css`
          background: linear-gradient(90deg, rgba(48, 190, 157, 0.7) 0%, rgba(0, 144, 255, 0.7) 100%);
          border: 2.08321px solid #000;
          color: #fff;
        `
      } else {
        return css`
          background: #fff;
          border: 2.08321px solid #0090FF;
          color: #9E9E9E;
        `
      }
    }}
  }
`
