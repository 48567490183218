import styled, { css } from "styled-components";

export const InputsWrapper = styled.div`
  display: flex;
  column-gap: 14px;
  margin-bottom: 46px;
`

export const InputWrapper = styled.div`
  position: relative;
  width: 44px;
  height: 44px;

  & input {
    padding: 6px 9px;
  }

  ${(props:{length?:number}) => {
    if (props.length && props.length > 2) {
      return css`
        width: 64px;
      `
    }
  }}
`

export const Example = styled.div`
  text-align: center;
  font-family: 'NanumSquareNeoBold';
  font-size: 12px;
  color: #484848;
`
