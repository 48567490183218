import { ReactElement } from "react";

export enum GenderType {
  Male,
  FeMale,
}

export interface ICategoryByAgeGroup {
  ageGroup: number,
  category: string,
  participants: number,
  gender: GenderType,
  explain: string,
  info: string[]
}

export interface ICategoryByAgeGroups {
  male: ICategoryByAgeGroup[],
  female: ICategoryByAgeGroup[],
}

export interface SignupGenderInlinedProps {
  selected: boolean;
  fade: {animation: boolean};
}

export interface IQuestion {
  selectedSeq: number;
  questionSeq: number;
}

export interface ISignup {
  gender: GenderType|null;
  setGender: (chooseGender:GenderType|null) => void;
  username: string;
  setUsername: (name:string) => void;
  age: number;
  setAge: (age: number) => void;
}

export interface IImageFrame {
  children: ReactElement|undefined;
  top: number;
  setMarginForNextElement: (height:number) => void;
}

export interface RouteParams {
  tendency: string;
}

export interface ICategoryInfo {
  category: string;
  explain: string;
  info: string;
}

export interface IUserSelectedSurveyTemp {
  [key: string]: {
    surveyType: number;
    userSelected: number;
    timestamp: number;
  }
}

export interface IUserSelectedSurveyTest {
  surveyNumber: number;
  surveyType: number;
  userSelected: number;
  timestamp: number;
}

export interface ICurrentSurveyData {
  page: number;
  data: {id: number, question: string, type: number}[];
}

export interface IUserInfo {
  gender: GenderType;
  genderType: string;
  age: number;
  ageGroup: number;
  username: string;
}

export interface ISurveySplash {
  page: number;
  isFinish: boolean;
  data: {
    userSelectedSurveys: IUserSelectedSurveyTest[]
    userSelectedTempList: IUserSelectedSurveyTemp
  }
}
