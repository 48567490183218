import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SVG from 'src/assets/images/svg/SVG';
import { httpErrorCodes } from 'src/common/fetch/errorCode';
import { requestPhoneCert, requestSignUp } from 'src/common/fetch/fetchs';
import { SignUpContextDispatch } from 'src/common/variables/context';
import { SignupContext, SignUpStep } from 'src/pages/Signup';
import { NextStep } from 'src/style/Signup';
import { GenderType } from 'src/type/types';
import { AuthContext } from '../context/auth';

interface Props {
  setFetchingSignUp: React.Dispatch<React.SetStateAction<boolean>>;
  setTransactionHash: React.Dispatch<React.SetStateAction<string>>;
}

const SignUpNextStep = ({setFetchingSignUp, setTransactionHash}:Props) => {

  const { setAuth } = useContext(AuthContext);
  const { state:SignState, dispatch:SignDispatch } = useContext(SignupContext);

  const [nextStep, setNextStep] = useState(false);

  const history = useHistory();

  const phoneCert = async(phoneNumber:string) => {
    setNextStep(false);
    const response = await requestPhoneCert({phoneNumber});
    setNextStep(true);

    if (response.error) {
      console.error('회원가입 인증번호 요청 에러');
      return false;
    }
    return true;
  }

  const onNextStep = async() => {
    if (!nextStep) return;

    if (SignState.step === SignUpStep.PHONENUMBER) {
      if (!SignState.phoneNumber) return;

      const result = await phoneCert(SignState.phoneNumber);
      if (!result) {
        alert('인증번호 요청 중 문제가 발생했습니다.');
        return;
      }
    }

    if (SignState.step === SignUpStep.PHONENUMBER_AUTHENTICATE) {
      if (!SignState.phoneNumberValidate) return;

      const signupBody = {
        realName: SignState.username,
        birthDay: SignState.birth,
        gender: SignState.gender as "MALE" | "FEMALE",
        phoneNumber: SignState.phoneNumber
      }

      setFetchingSignUp(true);
      const result = await requestSignUp(signupBody);
      setFetchingSignUp(false);

      if (result.error) {
        if (result.error.errorCode === httpErrorCodes.auth.MEMBER_DUPLICATE_EXCEPTION) {
          alert('이미 가입한 회원입니다.\n로그인 페이지로 이동합니다.');
          history.push('/signin');
        } else {
          alert('회원가입 중 문제가 발생했습니다.\n다시 시도해주세요.');
        }
        return;
      }

      const { token, transactionHash } = (result.data as ResponseSignUp).data;
      setTransactionHash(transactionHash);
      setAuth(token.accessToken);
    }

    SignDispatch({type:SignUpContextDispatch.setNextStep});
  }

  useEffect(() => {
    if (SignState.step === SignUpStep.USERNAME) {
      if (SignState.username) setNextStep(true);
      else setNextStep(false);
    } else if (SignState.step === SignUpStep.BIRTH) {
      if (SignState.birth) setNextStep(true);
      else setNextStep(false);
    } else if (SignState.step === SignUpStep.GENDER) {
      if (SignState.gender) setNextStep(true);
      else setNextStep(false);
    } else if (SignState.step === SignUpStep.PHONENUMBER) {
      if (SignState.phoneNumber) setNextStep(true);
      else setNextStep(false);
    } else if (SignState.step === SignUpStep.PHONENUMBER_AUTHENTICATE) {
      if (SignState.phoneNumberValidate) setNextStep(true);
      else setNextStep(false);
    }
  }, [SignState])

  useEffect(() => {
    if (SignState.step === SignUpStep.PHONENUMBER_AUTHENTICATE && nextStep) {
      onNextStep();
    }
  }, [nextStep, SignState])

  if (SignState.step === SignUpStep.WELCOME) return <></>;

  return (
    <NextStep 
    onClick={onNextStep}
    disabled={!nextStep}>
      <div>
        {nextStep ? <SVG.RightArrowBlack /> : <SVG.RightArrowGrey />}
      </div>
    </NextStep>
  );
};

export default SignUpNextStep;
