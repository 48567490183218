import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  text:string|React.ReactElement;
  callback?:() => void;
  styles?: {
    background?:string;
    border?:string;
    color?:string;
    fontSize?:number;
  }
}

export default function LinedButton({text, callback, styles}:Props){
  return (
    <Button 
    onClick={callback}
    styles={styles}
    >
      {text}
    </Button>
  )
}

const Button = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(90deg, #0090FF 0%, #AE73D6 100%);
  border: 2px solid #0F0F0F;
  color: #FFF;
  font-size: 14px;

  ${(props:{styles?:{
    background?:string, 
    border?:string,
    fontSize?:number,
    color?:string
  }}) => {
    if (props.styles) {
      return css`
        background: ${props.styles.background ?? 'linear-gradient(90deg, #0090FF 0%, #AE73D6 100%)'};
        border: ${props.styles.border ?? '2px solid #0F0F0F'};
        color: ${props.styles.color ?? '#FFF'};
        font-size: ${props.styles.fontSize ?? 14}px;
      `
    }
  }}
`
