import React from 'react';
import styled from 'styled-components';

const StoryContent = () => {
  return (
    <Container>
      <h3>위드의 스포츠 심리 테스트는 이렇게 시작되었어요.</h3>
      <p>기존 스포츠 시장에서 사용되는 선수 평가 모델은, 충분한 데이터, 그중에서도 정량 데이터 기반의 모델을 사용해오고 있습니다.</p><br/>
      <p>즉, 연령이 어리거나 소속 리그가 낮아 충분한 데이터가 부족한 선수들을 평가할 수 있는 합리적인 모델이 부재했습니다.</p><br/>
      <p>따라서 위드는, 이러한 선수들을 바르게 평가할 수 있는 새로운 모델을 통해 정당한 가치를 산정 받을 수 있는 데 도움이 될 수 있도록 다양한 데이터를 기반으로 최적의 평가 모델을 개발하고 있으며, 이 일의 일환으로 스포츠 심리 테스트를 개발하게 되었습니다.</p><br/>
      
      <h3>위드의 스포츠 심리 테스트는 충분한 연구를 통해 만들어졌어요.</h3>
      <p>위드의 심리평가 모델은 위드의 선수 평가 모델의 일환으로 연세대, 강원대, 경남대 심리연구소의 연구 및 WSA(WITH Service Alliance, 위드 서비스 참여 축구팀 연합) 2,000명의 인터뷰 및 파일럿 테스트를 통해 완성되었으며, 체계화된 선수 심리 유형에 대한 진단 기준을 포함하고 있습니다.</p><br/>
      <p>그럼 위드의 스포츠 심리 테스트를 통해 스포츠 유형을 확인해 보세요.</p><br/>
    </Container>
  );
};

export default StoryContent;

const Container = styled.div`
  h3 {
    margin-bottom: 4px;
    font-family: 'NanumSquareNeoExtraBold';
    font-weight: 800;
    font-size: 15px;
    line-height: 30px;
  }
  p {
    font-family: 'NanumSquareNeoBold';
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    color: #484848;
  }
`
