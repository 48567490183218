import { GenderType } from "src/type/types";
import styled, { css } from "styled-components";

export const SignupWrapper = styled.section`
  display: flex;
  column-gap: 21px;
`

export const Button = styled.button`
  outline: none;
  background: #222222;
  border: 1px solid #484848;
  border-radius: 8px;
  width: 129px;
  height: 251px;
  padding: 23px 0 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 17px;

  & > :last-child {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #484848;
  }

  ${(props:{selected:boolean}) => {
    if (props.selected) {
      return css`
        background: linear-gradient(199.29deg, #FE5E94 7.65%, #AE73D6 78.61%);
        & > :last-child {
          color: #FFF;
        }
      `
    }
  }}

  &:hover {
    border: 1px solid #FFF;
  }
`

export const GenderImg = styled.div`
  position: relative;
  height: 167px;
  ${(props:{gender:GenderType}) => {
    if (props.gender === GenderType.Male) {
      return css`
        width: 92px;
      `
    }
    if (props.gender === GenderType.FeMale) {
      return css`
        width: 109px;
      `
    }
  }}
`
