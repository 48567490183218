import React from 'react';
import { IMAGES } from 'src/common/variables/images';
import { Button, GenderImg } from 'src/style/Signup/gender';
import { GenderType } from 'src/type/types';

interface Props {
  gender: GenderType;
  selected: boolean;
  callback: () => void;
}

export default function SignUpGenderButton({gender, selected, callback}:Props) {
  
  return (
    <Button 
    selected={selected}
    onClick={callback}>
      <GenderImg gender={gender}>
        <img src={gender === GenderType.Male ? IMAGES.common.signupMale : IMAGES.common.signupFemale} 
        alt="남자" />
      </GenderImg>
      <div>
        {gender === GenderType.Male ? '남자' : '여자'}
      </div>
    </Button>
  )
}
