import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { slideImg } from 'src/common/variables/images';
// import Footer from 'src/component/Footer';
import LinedButton from 'src/component/LinedButton';
import ImageFrame from "src/component/ImageFrame";
import Slider, { Settings } from 'react-slick';
import { requestStartCheck } from 'src/common/fetch/fetchs';
import { httpErrorCodes } from 'src/common/fetch/errorCode';
import { GlobalPopupContext } from 'src/component/popup/GlobalPopup';
import { GlobalPopupContextDispatch } from 'src/common/variables/context';
import StoryContent from 'src/component/StoryContent';
import useMountedUpScroll from 'src/hook/useMountedUpScroll';
import MainHeader from 'src/component/Header/MainHeader';
import {
  StoryWrapper, Background, Main, 
  Title, ListWrapper, ImageWrapper, 
  ButtonWrapper, DivideLine, OtherTypeSection, CategorySlideWrap,
} from 'src/style/mbti'
import Loading from 'src/component/Loading';
import CategorySlide from 'src/component/CategorySlide';
import Footer from 'src/component/Footer';

const slideSettings:Settings = {
  dots: true,
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  appendDots: dots => (
    <div
      style={{
        position: "absolute",
      }}
    >
      <ul style={{
        display: "flex",
        justifyContent: "center",
        columnGap: "6px",
        position: "absolute",
        bottom: "0px", 
        left: "50%",
        transform: "translateX(-50%)"
      }}>
        {dots} 
      </ul>
    </div>
  ),
  customPaging: () => (
    <div
      style={{
        width: "8px",
        height: "8px",
        backgroundColor: "#FFF",
        borderRadius: "4px"
      }}
    />
  )
}

function Mbti() {
  useMountedUpScroll();

  const history = useHistory();
  const { dispatch } = useContext(GlobalPopupContext);
  const [fetchingStartTest, setFetchingStartTest] = useState(false);

  const startTest = async () => {
    if (fetchingStartTest) return;

    setFetchingStartTest(true);
    const response = await requestStartCheck();
    setFetchingStartTest(false);

    if (response.error) {
      if (response.error.errorCode === httpErrorCodes.verify.WSTI_RECORD_ALREADY_EXIST_EXCEPTION) {
        dispatch({
          type:GlobalPopupContextDispatch.openPopup,
          payload: {
            title: <>이미 검사 기록이 존재합니다</>,
            info: [<>결과 페이지로 이동하시겠습니까?</>],
            popupType: 'CONFIRM',
            callback: () => history.push('/mbti/result'),
            styles: {titleTextAlign:'center'}
          }
        })
      }
      else {
        dispatch({
          type:GlobalPopupContextDispatch.openPopup,
          payload: {
            title: <>테스트를 진행할 수 없습니다</>,
            info: [<>관리자에게 문의해주세요</>],
            styles: {titleTextAlign:'center'}
          }
        })
      }
      return;
    }

    dispatch({
      type: GlobalPopupContextDispatch.openPopup,
      payload: {
        title: <>테스트를 시작하시겠습니까?</>,
        info: [
          <>문항수 : 69</>,
          <>예상시간 : 5min</>,
          <>비용 : 5 WIKEN</>,
          <>(비용은 결과 제출시 자동 차감됩니다)</>
        ],
        popupType: 'CONFIRM',
        callback: () => history.push('/mbti/survey'),
        styles: {titleTextAlign:'center'}
      }
    })
  }

	return (
    <Background>

      <Main>

        <MainHeader
        backButton={true}
        logoStyle='White'
        myInfoButton={true}
        styles={{marginBottom:10}} />

        <Title>스포츠 심리테스트</Title>

        <ListWrapper>
          <Slider {...slideSettings}>
            {slideImg.map((img, idx) => (
              <ImageWrapper key={idx}>
                <img src={img} alt="" />
              </ImageWrapper>
            ))}
          </Slider>
        </ListWrapper>

        <ButtonWrapper onClick={startTest}>
          <LinedButton text={!fetchingStartTest ? "테스트 시작" : <Loading size={0.7}/>} />
        </ButtonWrapper>

        <DivideLine />

        <StoryWrapper>
          <h2>연구 스토리</h2>
          <ImageFrame>
            <StoryContent />
          </ImageFrame>
        </StoryWrapper>

        <DivideLine />

        <OtherTypeSection>
          <h2>유형 구경하기</h2>
          <CategorySlideWrap>
            <CategorySlide />
          </CategorySlideWrap>
        </OtherTypeSection>

        <ButtonWrapper onClick={() => history.push('/mbti/categorys')}>
          <Link to="/mbti/categorys">
            <LinedButton text="모든 유형보기" />
          </Link>
        </ButtonWrapper>

      </Main>
      <Footer />
    </Background>
	)
}

export default Mbti;
