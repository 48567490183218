import React from 'react';
import { colors } from 'src/style/styled';
import styled from 'styled-components';

export default function AbilityItem({
  type, 
  ability,
  view
}:{
    type: string, 
    ability: number,
    view: boolean
  }){
  return (
    <Wrapper>
      <AbilityInfo>
        <div>{type}</div>
        <div>{Number(ability).toFixed(1)}</div>
      </AbilityInfo>
      <AbilityBar value={view ? ability : 0} max={5} />
    </Wrapper>
  )
}

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
`

const AbilityInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  color: #FFF;
`

const AbilityBar = styled.progress`
  margin: 0 auto 18px;
  border: 1px solid #040000;
  background-color: #fff;
  width: 100%;
  height: 16px;
  border-radius: 6px;
  padding: 2px;

  -webkit-appearance: none;
  -moz-appearance: none;
  &::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 6px;
  }
  &::-webkit-progress-value {
    background: linear-gradient(90deg, #FE5E94 0%, #AE73D6 100%);
    transition: 1.5s;
    border-radius: 6px;
    /* border-right: 1.5px solid #000; */
    /* border-top-right-radius: 4px; */
    /* border-bottom-right-radius: 4px; */
  }
`
