import {useEffect} from "react";

export default function useGreetMessage() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log("Welcome to the WSTI!");
    } else {
      console.log("WSTI in dev");
    }
  }, [])
}