import React, { useContext, useEffect } from 'react';
import SVG from 'src/assets/images/svg/SVG';
import { requestGetMember } from 'src/common/fetch/fetchs';
import { AuthContextDispatch } from 'src/common/variables/context';
import { PlayerCardType } from 'src/common/variables/images';
import BackBtn from 'src/component/BackBtn';
import Card from 'src/component/Card';
import { AuthContext } from 'src/component/context/auth';
import Footer from 'src/component/Footer';
import GlobalLoading from 'src/component/GlobalLoading';
import useMountedUpScroll from 'src/hook/useMountedUpScroll';
import { BackBtnWrap } from 'src/style/category';
import { Background, Main } from 'src/style/mbti';
import { CardWrap, Info, Logout, Title, Wiken, Wrapper } from 'src/style/myinfo/myinfo';

const MyInfo = () => {
  useMountedUpScroll();
  const { state, dispatch, setLogout } = useContext(AuthContext);
  
  async function reloadUserData() {
    const response = await requestGetMember();
    if (response.error || !response.data) return;
    dispatch({
      type:AuthContextDispatch.updateAuth,
      payload:response.data.data 
    })
  }

  useEffect(() => {
    reloadUserData();
  }, [])

  if (!state.signChecked) return <GlobalLoading /> 
  
  return (
    <Background>
      <Main>
        <BackBtnWrap>
          <BackBtn color="#000" />
        </BackBtnWrap>
        <Title>내 유형기록</Title>
        <Logout onClick={setLogout}>로그아웃</Logout>
        <Wrapper>
          <CardWrap>
            <Card playerType={
              (state.member as Member).wsti ? 
              `${(state.member as Member).wsti.title} 스포츠인` : 
              ''
              }>
              {(state.member as Member).wsti && (
                <img src={PlayerCardType[(state.member as Member).wsti.type]} alt="" />
              )}
            </Card>
          </CardWrap>
          <Info>
            <div>이름</div>
            <div>{(state.member as Member).realName}</div>
          </Info>
          <Info>
            <div>생일</div>
            <div>{(state.member as Member).birthDay.split('/').join('.')}</div>
          </Info>
          <Info>
            <div>보유자산</div>
            <div>
              +{(state.member as Member).wiken ?? 0} WIKEN
              <Wiken>
                <SVG.WikenCoin width={21} height={21}/>
              </Wiken>
            </div>
          </Info>
        </Wrapper>
      </Main>
      <Footer />
    </Background>
  );
};

export default MyInfo;
