import React, { PropsWithChildren } from 'react';
import SVG from 'src/assets/images/svg/SVG';
import { Container, Cover, Logo, Player, PlayerType, WSTI } from 'src/style/component/card';

interface Props {
  playerType?:string;
  playerStyles?:{
    width:number;
    height:number;
  }
}

const Card = ({children, playerType, playerStyles}:PropsWithChildren<Props>) => {
  return (
    <Container>
      <WSTI><SVG.LogoWSTI /></WSTI>
      {/* 이미지 */}
      <Player styles={playerStyles}>{children}</Player>
      {/* 타입 */}
      {playerType && <PlayerType>{playerType}</PlayerType>}
      <Logo><SVG.LogoWITH style={{width:'51px',height:'9px'}}/></Logo>
      {!children && (
        <Cover>
          <div>?</div>
          <div>
            <div>심리테스트를</div>
            <div>완료해주세요</div>
          </div>
        </Cover>
      )}
    </Container>
  );
};

export default Card;