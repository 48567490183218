import { useRef, useState } from "react";

/**
 * return time format -> mm:ss
 */

export default function useTimer(initTime=1000*60*3) {
  const timer = useRef<NodeJS.Timer>();
  const [time, setTime] = useState('00:00');
  const [status, setStatus] = useState(false);

  const startTimer = () => {
    if (timer.current) clearInterval(timer.current);
    setStatus(true);

    const standTime = new Date().getTime() + initTime;
    timer.current = setInterval(() => {
      const currentTime = new Date().getTime();
      if (standTime - currentTime <= 0) {
        setTime(formatTime(0));
        setStatus(false);
        quitTimer();
      } else {
        setTime(formatTime(standTime - currentTime));
      }
    }, 1000)
  }

  const quitTimer = () => {
    if (!timer.current) return;
    clearInterval(timer.current);
    setTime('00:00');
    setStatus(false);
  }

  const formatTime = (timeDiff:number):string => {
    const m = String(Math.floor((timeDiff/1000) / 60));
    const s = String(Math.floor((timeDiff/1000)) - (+m*60));
    const mm = m.length == 1 ? '0' + m : m;
    const ss = s.length == 1 ? '0' + s : s;
    return mm + ':' + ss;
  }

  return {
    time,
    status,
    startTimer,
    quitTimer
  }
}