import { IQuestion } from "src/type/types";

export default function surveySelectDelegation(
  e: React.MouseEvent<HTMLUListElement>,
  handleSelectSurvey: ({questionSeq, selectedSeq}:IQuestion) => void,
  handleVerificationIsPlayer: (requireCheck:boolean) => void,
){
  const optionList = (e.target as HTMLElement).closest('.optionList');
  if(optionList) {
    const surveyItem = optionList.closest('.surveyItem');
    const selectedOption = (e.target as HTMLElement).dataset.seq;
    const selectedIsPlayer = (e.target as HTMLElement).dataset.isplayer;

    if (!surveyItem) return;
    if (selectedOption) {
      const qidx = Number((surveyItem as HTMLLIElement).dataset.seq);
      handleSelectSurvey({
        questionSeq: qidx,
        selectedSeq: Number(selectedOption)
      })
      return;
    }
    if (selectedIsPlayer) {
      handleVerificationIsPlayer(selectedIsPlayer === 'true')
    }
  }
}
