export default function handleSurveyScroll({
  ulRef, questionSeq
}:{
  ulRef: React.RefObject<HTMLUListElement>,
  questionSeq: number
}) {
  let scroll = 0;
  const surveyList = Array.from(ulRef.current?.children as HTMLCollection);
  surveyList.forEach((cur) => {
    if( Number((cur as HTMLLIElement).dataset.seq) < questionSeq) {
      scroll += (cur as HTMLLIElement).offsetHeight + 10;
    }
  })
  
  // ulRef.current?.scrollTo({top:scroll, behavior: 'smooth'});
  requestAnimationFrame(() => ulRef.current?.scrollTo({top:scroll, behavior: 'smooth'}))
}
