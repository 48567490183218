import React from 'react';
import styled from 'styled-components';
import Loading from './Loading';

const GlobalLoading = () => {
  return (
    <Container>
      <Loading size={1} />
    </Container>
  );
};

export default GlobalLoading;

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
`
