import React, { useEffect, useRef } from 'react';
import { IMAGES } from 'src/common/variables/images';
import styled, { keyframes } from 'styled-components';

export default function BallList({time}:{time:number}) {

  const ballRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (!ballRef.current) return;
    const ballLiArray = ballRef.current.querySelectorAll('li');
    let animationIdx = 0;

    ballLiArray[animationIdx].classList.add('animate');
    const ballAnimationTimeout = setInterval(() => {
      animationIdx++;
      if (animationIdx == 4) {
        clearInterval(ballAnimationTimeout);
        return;
      }
      ballLiArray[animationIdx].classList.add('animate');
    }, 200);
  }, []);

  return (
    <BallWrap time={time} ref={ballRef}>
      {Array(4).fill(IMAGES.icon.ball).map((v, idx) => (
        <li key={idx}>
          <img src={v} alt="" />
        </li>
      ))}
    </BallWrap>
  )
}

export const Rotate = keyframes`
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

const BallWrap = styled.ul`
  padding: 0;
  display: flex;
  /* justify-content: space-around; */
  column-gap: 21px;
  width: max-content;
  margin: 0 auto;
  list-style: none;
  & > li {
    will-change: transform;
    & > img {
      width: 49px;
      height: 49px;
    }
    &.animate {
      animation: ${Rotate} ${(props:{time:number})=>props.time}s infinite;
    }
  }
`
