import React, { useRef, useEffect } from 'react';
import { 
  Inlined, Outlined, Question,
  SelectConfirmContainer,
  SelectContainer, SelectItem, Sequence
 } from 'src/style/survey/Item';

interface ISurveyItem {
  question: string;
  qidx: number;
  qtype: number;
  isPlayer?: boolean;
  userSelected: number|undefined;
}

function SurveyItem({
  question, 
  qidx, 
  qtype,
  isPlayer,
  userSelected
}: ISurveyItem){
  const inlineRef = useRef<HTMLDivElement>(null);
  const outlineRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    let pHeight = inlineRef.current?.offsetHeight;
    if(pHeight) {
      pHeight += 3.16;
      (outlineRef.current as HTMLLIElement).style.height = String(pHeight)+'px';
    }
  }, [inlineRef]);

  return (
    <Outlined 
      ref={outlineRef} 
      done={qtype !== -1 ? !!userSelected : isPlayer !== undefined}  
      data-seq={qidx}
      className="surveyItem"
      >
      <Inlined ref={inlineRef}>
        <Sequence>Q{qidx}.</Sequence>
        <Question>{question}</Question>
        {qtype !== -1 ? (
          <>
            <SelectContainer
            className="optionList"
            selected={userSelected}
            >
              <li>비동의</li>
              {Array(5).fill(0).map((_, idx) => (
                <SelectItem 
                  key={idx} 
                  data-seq={idx+1}>
                  {idx+1}
                </SelectItem>
              ))}
              <li>동의</li>
            </SelectContainer>
          </>
        ) : (
          <>
            <SelectConfirmContainer
            className="optionList"
            selected={isPlayer}
            >
              <li>아니요</li>
              <li data-isplayer={false}>X</li>
              <li data-isplayer={true}>O</li>
              <li>예</li>
            </SelectConfirmContainer>
          </>
        )}
      </Inlined>
    </Outlined>
  )
}

export default React.memo(SurveyItem);
