import styled from "styled-components";

export const Background = styled.div`
  background-color: #0F0F0F;
  min-height: 100vh;
`

export const Main = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 393px;
  min-height: calc(100vh - 47px - 47px - 9px);

  /* @media (max-width:852px) {
    min-height: 852px;
  } */
`

export const Header = styled.header`
  position: relative;
  padding-top: 20px;
  display: flex;
  align-items: center;
`

export const BackArrow = styled.div`
  position: absolute;
  left: 20px;
  width: 9.5px;
  height: 17.5px;
  cursor: pointer;
  &.hidden {
    display: none;
  }
`

export const Logo = styled.div`
  margin: 0 auto;
  width: 74px;
  height: 36px;
`

export const Contents = styled.div`
  padding-top: 133px;
  &.welcome {
    padding-top: 46px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Heading = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #FFF;
  margin-bottom: 25px;

  & .small {
    font-size: 12px;
  }
`

export const Input = styled.input`
  position: relative;
  width: 100%;
  height: 100%;
  background: #222222;
  border: 1px solid #484848;
  border-radius: 8px;
  text-align: center;
  color: #FFF;
  outline: none;

  &[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

export const NextStep = styled.button`
  position: absolute;
  outline: none;
  border: none;
  width: 54px;
  height: 42px;
  right: 24px;
  top: 16px;
  background: #FFF;
  border-radius: 6px;

  & > :first-child {
    position: absolute;
    width: 18px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:disabled {
    background: #222;
  }

  /* @media (min-height:852px) {
    bottom: 30%;
  } */
`