export const regexYear = (value:string) => {
  if (value.match(/\D/g)) return false;
  if (value.length > 4) return false;
  if (value.length == 4 && !(value >= '1900' && value <= '2023')) return false;
  return true;
}

export const regexMonth = (value:string) => {
  if (value.match(/\D/g)) return false;
  if (value.length > 2) return false;
  if (value.length == 2 && !(value >= '01' && value <= '12')) return false;
  return true;
}

export const regexDay = (value:string) => {
  if (value.match(/\D/g)) return false;
  if (value.length > 2) return false;
  if (value.length == 2 && !(value >= '01' && value <= '31')) return false;
  return true;
}

export const regexName = (value:string) => {
  // if (!!value.match(/[^가-힣|ㄱ-ㅎ]/g)) return false;
  if (value.length > 25) return false;
  return true;
}

export const inputKeyDownNumberType = (e:React.KeyboardEvent<HTMLInputElement>) => {
  if (['e', 'E', '+', '-'].includes(e.key)) {
    e.preventDefault();
  }
}

export const regexPhoneNumber = (value:string) => {
  if (value.match(/[^0-9]/g)) return false;
  if (value.length > 11) return false;
  if (value.length >= 3 && !(value.slice(0, 3).match(/010/))) return false;
  return true;
}
