export const httpErrorCodes = {
  verify: {
    VERIFICATION_CODE_ERROR: 10001, // 인증번호 불일치
    CERT_NUMBER_TOO_MANY_TRY: 10003, // 시도 횟수 초과
    CERT_NUMBER_EXPIRED: 10004, // 인증번호 만료
    CERT_NOT_FOUND: 10006, // 인증번호 존재하지 않음
    WSTI_RECORD_ALREADY_EXIST_EXCEPTION: 30001,
    WSTI_PAYMENT_FAIL_EXCEPTION: 30003,
    WIKEN_LACK_EXCEPTION: 30004,
    WSTI_RECORD_NOT_FOUND_EXCEPTION: 30005,
    WSTI_ALREADY_PAID_EXCEPTION: 30006,
    WSTI_PLAYER_VALID_CODE_NOT_FOUND_EXCEPTION: 30008,
  },
  auth: {
    MEMBER_DUPLICATE_EXCEPTION: 20004,
  }
}