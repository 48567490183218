import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IMAGES } from 'src/common/variables/images';
import { ISurveySplash, IUserSelectedSurveyTest } from 'src/type/types';
import styled from 'styled-components';
import LinedButton from './LinedButton';
import SurveySpinner from '../component/SurveySpinner';
import SVG from 'src/assets/images/svg/SVG';
import { requestGetResult, requestSubmitTest } from 'src/common/fetch/fetchs';
import { httpErrorCodes } from 'src/common/fetch/errorCode';
import { setCookieValue } from './context/auth';
import { COOKIE_TRANSACTION_HASH } from 'src/common/variables/constant';

const p = [
  '멋진걸? 잘하고 있어!',
  '좋아 계속해보자구~',
  '와우 아주 나이스!!',
  '끝~! 두근두근',
  '결과보러 가볼까?'
]

export default function SurveySplash({
  page, 
  isFinish,
  data
} : ISurveySplash){

  const [spinner, setSpinner] = useState<boolean>(false);
  const [requestResult, setRequestResult] = useState<boolean>(false);
  const history = useHistory();

  const handleOnClick = useCallback(() => {
    setSpinner(true);
  }, [])

  const putSurveyResult = useCallback(async() => {
    const surveyData:IUserSelectedSurveyTest[] = [];
    delete data.userSelectedTempList[70];

    for (let key in data.userSelectedTempList) {
      surveyData.push({
        surveyNumber: Number(key),
        surveyType: data.userSelectedTempList[key].surveyType,
        userSelected: data.userSelectedTempList[key].userSelected,
        timestamp: data.userSelectedTempList[key].timestamp
      })
    }

    const formData = {
      // surveyData: data.userSelectedSurveys.sort((a,b) => a.surveyNumber > b.surveyNumber ? 1 : a.surveyNumber < b.surveyNumber ? -1 : 0),
      surveyData: surveyData.sort((a,b) => a.surveyNumber > b.surveyNumber ? 1 : a.surveyNumber < b.surveyNumber ? -1 : 0),
      userAgent: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Web'
    }
    
    let requestStartTime = new Date().getTime();

    // submit request
    // 
    const submitResponse = await requestSubmitTest(formData);

    if (submitResponse.error || !submitResponse.data) {
      if (!submitResponse.data) {
        alert('결과 제출 간에 문제가 발생했습니다.\n관리자에게 문의해주세요.');
      }
      if (submitResponse.error.errorCode === httpErrorCodes.verify.WIKEN_LACK_EXCEPTION) {
        alert('wiken 이 부족합니다.');
      }
      else if (submitResponse.error.errorCode === httpErrorCodes.verify.WSTI_PAYMENT_FAIL_EXCEPTION) {
        alert('wiken 결제를 실패했습니다.');
      }
      history.push('/');
      return;
    }

    const hashPath = submitResponse.data.data;
    setCookieValue(COOKIE_TRANSACTION_HASH, hashPath);

    // success !
    let requestEndTime = new Date().getTime();
    if (requestEndTime - requestStartTime >= 5000) {
      history.push('/mbti/result');
      // history.push({
      //   pathname:'/mbti/result',
      //   state: hashPath
      // })
    } else {
      setTimeout(() => {
        history.push('/mbti/result');
        // history.push({
        //   pathname:'/mbti/result',
        //   state: hashPath
        // })
      }, 5000 - (requestEndTime - requestStartTime))
    }
  }, []);

  useEffect(() => {
    if(spinner && !requestResult) {
      setRequestResult(true);
      putSurveyResult();
    }
  }, [spinner])

  return (
    <Background isFinish={isFinish}>
      {
        spinner ? (
          <SurveySpinner />
        ) : (
          <Main>
            <Logo>
              {isFinish ? <SVG.LogoWSTIWhite /> : <SVG.LogoWSTI />}
            </Logo>
            <Splash>
              {isFinish ? <img src={IMAGES.common.splashWhite} alt="" /> :
              <img src={IMAGES.common.splash} alt="" /> }
            </Splash>
            <Header>
              {
                isFinish ? (
                  <>
                    <div>{p[3]}</div>
                    <div>{p[4]}</div>
                  </>
                ) :
                page === 3 ? p[0] :
                page === 5 ? p[1] :
                p[2]
              }
            </Header>
            <ButtonWrapper onClick={handleOnClick} isFinish={isFinish}>
              <LinedButton text="좋아요!" />
            </ButtonWrapper>
          </Main>
        )
      }
    </Background>
  )
}

const Background = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: ${(props:{isFinish:boolean}) => props.isFinish ? 'linear-gradient(199.29deg, #FE5E94 7.65%, #AE73D6 78.61%)' : '#0F0F0F'};
`

const Main = styled.div`
  padding: 20px 0 67px;
  max-width: 393px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.div`
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #FFF;
  margin-bottom: 44px;
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: ${(props:{isFinish:boolean}) => props.isFinish ? 'block' : 'none'};
  position: relative;
  width: 345px;
  height: 48px;
`

const Logo = styled.div`
  position: relative;
  width: 74px;
  height: 36px;
  margin-bottom: 32px;
`

const Splash = styled.div`
  position: relative;
  width: 342px;
  height: 492px;
`
