import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Category from 'src/pages/Category';
import Categorys from 'src/pages/Categorys';
import Mbti from 'src/pages/Mbti';
import Intro from 'src/pages/Intro';
import NotFound from 'src/pages/NotFound';
import Result from 'src/pages/Results';
import SignIn from 'src/pages/SignIn';
import Signup from 'src/pages/Signup';
import Survey from 'src/pages/Survey';
import AuthRoute from './AuthRoute';
import MyInfo from 'src/pages/MyInfo';

// const Mbti = lazy(() => import('src/pages/Mbti'));
// const Categorys = lazy(() => import('src/pages/Categorys'));
// const Category = lazy(() => import('src/pages/Category'));
// const Signup = lazy(() => import('src/pages/Signup'));
// const Result = lazy(() => import('src/pages/Results'));
// const Survey = lazy(() => import('src/pages/Survey'));
// const NotFound = lazy(() => import('src/pages/NotFound'));

export default function Routes() {
  return (
    <Router>
      <Switch>
          <Route exact path="/" component={Intro} />
          <AuthRoute exact path="/mbti" component={<Mbti />} />
          <Route path="/signup" component={Signup} />
          <Route path="/signin" component={SignIn} />
          <AuthRoute path="/myinfo" component={<MyInfo />} />
          <AuthRoute exact path="/mbti/survey" component={<Survey />} />
          <Route exact path="/mbti/result" component={Result} />
          <Route path="/mbti/result/:hashPath" component={Result} />
          <Route exact path="/mbti/categorys" component={Categorys} />
          <Route path="/mbti/categorys/:tendency/" component={Category} />
          <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  )
}
