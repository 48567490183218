import './App.css';
import "src/font.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import Routes from 'src/router/routes';
import useInitFirestore from 'src/hook/useInitFirestore';
import AuthProvider from './component/context/auth';
import usePreloadFiles from './hook/usePreloadFiles';
import GlobalPopupProvider from './component/popup/GlobalPopup';
import useGreetMessage from "./hook/useGreetMessage";

function App() {
  useInitFirestore();
  usePreloadFiles();
  useGreetMessage();
  return (
    <AuthProvider>
      <GlobalPopupProvider>
        <Routes />
      </GlobalPopupProvider>
    </AuthProvider>
  );
}

export default App;
