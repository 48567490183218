import { useMemo } from "react";
import { IMAGES } from "src/common/variables/images";

export default function usePreloadFiles() {
  useMemo(() => {
    const preLoadFile = 
    [
      IMAGES.common.MESSI,
      IMAGES.common.gerrard,
      IMAGES.common.honaldo,
      IMAGES.common.kangte,
      IMAGES.common.son,
      IMAGES.common.splash,
      IMAGES.common.splashWhite,
    ]
    // let loadedFileCnt = 0;
    preLoadFile.forEach(img => {
      const image = new Image();
      image.src = img;
      // image.addEventListener('load', () => {
      //   loadedFileCnt++;
      //   console.log(loadedFileCnt)
      //   if (loadedFileCnt == preLoadFile.length) console.log('preload done.');
      // })
    })
  }, [])
}