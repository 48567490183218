import React from 'react';
import { Link } from 'react-router-dom';
import CategoryCard from 'src/component/CategoryCard';
import LinedButton from 'src/component/LinedButton';
import { DivideLine } from 'src/style/mbti';
import useMountedUpScroll from 'src/hook/useMountedUpScroll';
import {
  Background, ButtonWrapper, CategoryCards, 
  Intro, RoutesWrapper, 
  Title, Wrapper
} from '../style/categorys';
import MainHeader from 'src/component/Header/MainHeader';
import { CategoryDefaultData } from 'src/common/variables/dataset';
import Footer from 'src/component/Footer';

export default function Categorys(){
  useMountedUpScroll();

  return (
    <Background>
      <Wrapper>
        <MainHeader 
        backButton={true}
        styles={{marginBottom:20}} />
        <Title>모든 유형 보기</Title>
        <DivideLine />
        <Intro>
          스포츠 유형 검사는 연구를 통해 개발된 12개의 심리 특성을 기반으로 심리 특성을 평가함과 동시에 선수가 가진 리더십 역량, 자기조절 역량, 정신적 강인함 역량, 팀웍 역량, 경기력 역량을 예측할 수 있는 테스트입니다.
          테스트를 통한 분석 결과는 총 4가지 유형으로 분류하였습니다.
        </Intro>
        <CategoryCards>
          {CategoryDefaultData.map(data => (
            <CategoryCard data={data} key={data.tendency} />
          ))}
        </CategoryCards>
        <RoutesWrapper>
          <ButtonWrapper>
            <Link to="/">
              <LinedButton text="홈으로 가기" />
            </Link>
          </ButtonWrapper>
          <ButtonWrapper>
            <Link to="/mbti">
              <LinedButton text="테스트 시작" />
            </Link>
          </ButtonWrapper>
        </RoutesWrapper>
      </Wrapper>
      <Footer />
    </Background>
  )
}
