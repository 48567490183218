import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import BackBtn from 'src/component/BackBtn';
import { useHistory } from 'react-router-dom';
import { ICurrentSurveyData, IQuestion, IUserSelectedSurveyTest, IUserSelectedSurveyTemp } from 'src/type/types';
import SurveyItem from 'src/component/SurveyItem';
import { surveysData } from 'src/common/variables/dataset';
import { SurveyPageTitle } from 'src/component/SurveyPageTitle';
import SurveyRefresh from 'src/component/SurveyRefresh';
import SurveyProgessBar from 'src/component/SurveyProgressBar';
import LinedButton from 'src/component/LinedButton';
import { HeaderWrapper, Main, Background, SurveyList, ButtonWrapper, VerificationInput } from 'src/style/survey';
import SurveySplash from 'src/component/SurveySplash';
import useMountedUpScroll from 'src/hook/useMountedUpScroll';
import handleSurveyScroll from 'src/common/util/handleSurveyScroll';
import surveySelectDelegation from 'src/common/util/surveySelectDelegation';
import selectSurvey from 'src/common/util/selectSurvey';
import nextStepSurvey from 'src/common/util/nextStepSurvey';
import { requestStartCheck, requestVerifyIsPlayer } from 'src/common/fetch/fetchs';
import Footer from 'src/component/Footer';
import { GlobalPopupContext } from 'src/component/popup/GlobalPopup';
import { GlobalPopupContextDispatch } from 'src/common/variables/context';
import { httpErrorCodes } from 'src/common/fetch/errorCode';

export default function Survey(){
  useMountedUpScroll();
  
  const history = useHistory();
  const { dispatch:globalPopupContextDispatch } = useContext(GlobalPopupContext);
  
  const lastPage = useRef( surveysData.length % 10 ? 
    Math.floor(surveysData.length / 10) + 1 : Math.floor(surveysData.length / 10)
  );
  const [userSelectedSurveys, setUserSelectedSurveys] = useState<IUserSelectedSurveyTest[]>([]);
  const [userSelectedTempList, setUserSelectedTempList] = useState<IUserSelectedSurveyTemp>({});
  const [isPlayer, setIsPlayer] = useState<boolean|undefined>(undefined);
  const [isPlayerChecking, setIsPlayerChecking] = useState(false);
  const [currentSurveyData, setCurrentSurveyData] = useState<ICurrentSurveyData>({
    page: 1,
    data: surveysData.slice(0, 10)
  });
  const ulRef = useRef<HTMLUListElement>(null);
  const [splash, setSplash] = useState<boolean>(false);
  const [isFinishSurvey, setIsFinishSurvey] = useState<boolean>(false);

  const isDoingTesting = async() => {
    const response = await requestStartCheck();
    if (response.error) {
      history.push('/');
    }
  }

  useEffect(() => {
    isDoingTesting()
  }, [])

  useEffect(() => {
    if (splash && !isFinishSurvey) {
      setTimeout(() => {
        setSplash(false);
      }, 1000);
    }
  }, [splash]);
  
  const handleScroll = useCallback((questionSeq:number) => {
    handleSurveyScroll({ ulRef, questionSeq });
  }, []);

  const handleSelectSurvey = useCallback(({questionSeq, selectedSeq}:IQuestion) => {
    selectSurvey({ questionSeq, selectedSeq, setUserSelectedSurveys, setUserSelectedTempList });
    handleScroll(questionSeq);
  }, []);

  const handleVerificationIsPlayer = (requireCheck:boolean) => {
    if (isPlayer) return;

    const checkCode = async() => {
      if (isPlayerChecking) return;

      setIsPlayerChecking(true);
      const input = document.querySelector('.globalPopup input') as HTMLInputElement;
      const callbackButton = document.querySelector('.globalPopup button') as HTMLButtonElement;
      const code = input.value;
      callbackButton.disabled = true;
      callbackButton.textContent = '확인 중...';
      const response = await requestVerifyIsPlayer(code);
      setIsPlayerChecking(false);
      callbackButton.disabled = false;
      callbackButton.textContent = '확인';
      
      if (response.error || !response.data) {
        if (response.error.errorCode === httpErrorCodes.verify.WSTI_PLAYER_VALID_CODE_NOT_FOUND_EXCEPTION) {
          alert('올바르지 않은 코드입니다.');
        }
        return;
      }

      setIsPlayer(true);
      setUserSelectedTempList((state) => {
        return {
          ...state,
          70: {
            surveyType: -1,
            timestamp: new Date().getTime(),
            userSelected: Number(true)
          }
        }
      })
      globalPopupContextDispatch({type:GlobalPopupContextDispatch.closePopup});
    }

    if (requireCheck) {
      globalPopupContextDispatch({
        type: GlobalPopupContextDispatch.openPopup,
        payload: {
          title: <div style={{textAlign:'center'}}>프로 인증 코드를 입력해주세요.</div>,
          info: [
            <VerificationInput
            onKeyDown={(e) => {
              if (e.key === 'Enter') checkCode()
            }}
            autoFocus={true} />
          ],
          callback: checkCode,
          option: {
            preventAutoClose: true
          }
        }
      })
    } else {
      setIsPlayer(false);
      setUserSelectedTempList((state) => {
        return {
          ...state,
          70: {
            surveyType: -1,
            timestamp: new Date().getTime(),
            userSelected: Number(false)
          }
        }
      })
    }
  }

  const onSelect = useCallback((e:React.MouseEvent<HTMLUListElement>) => {
    surveySelectDelegation(e, handleSelectSurvey, handleVerificationIsPlayer);
  }, [isPlayer]);

  const handleNextStepSurvey = useCallback(() => {
    nextStepSurvey({
      currentSurveyData,
      handleScroll,
      lastPage,
      setCurrentSurveyData,
      setIsFinishSurvey,
      setSplash,
      ulRef,
      userSelectedTempList,
    })
  }, [currentSurveyData, userSelectedTempList])

  const refreshSurvey = useCallback(() => {
    if(window.confirm('처음부터 다시 시작하시겠습니까?')) {
      setUserSelectedSurveys([]);
      setUserSelectedTempList({});
      setCurrentSurveyData({
        page: 1,
        data: surveysData.slice(0, 10)
      })
      ulRef.current?.scrollTo({top: 0});
      if (isPlayer !== undefined && !isPlayer) setIsPlayer(undefined);
    }
  }, []);

  const cancleSurvey = useCallback(() => {
    if(window.confirm('설문을 취소하시겠습니까?')) {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    const isMobile = !!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    if (!isMobile) return;

    // check user selected survey in page 
    let isFinishCurrentPage = true;
    let s = currentSurveyData.page * 10 - 9;
    let e = currentSurveyData.page * 10;
    e = e > surveysData.length ? surveysData.length : e;
    for (let i = s; i <= e; i++) {
      if (!userSelectedTempList[i]) {
        isFinishCurrentPage = false;
        break;
      }
    }
    
    if (isFinishCurrentPage) {
      window.scrollTo({top:window.innerHeight})
    }
  }, [userSelectedTempList, currentSurveyData])

  return (
    splash ?
    ( 
      <SurveySplash 
        page={currentSurveyData.page - 1}
        isFinish={isFinishSurvey}
        data={{userSelectedSurveys, userSelectedTempList}}/>
    ) : 
    (
      <Background>

        <Main>

          <HeaderWrapper>
            <BackBtn anotherHandler={cancleSurvey} color="#000"/>
            <SurveyPageTitle current={currentSurveyData.page} full={lastPage.current}/>
            <SurveyRefresh refresh={refreshSurvey}/>
          </HeaderWrapper>
          
          <SurveyProgessBar current={currentSurveyData.page} full={lastPage.current}/>
          
          <SurveyList ref={ulRef} onClick={onSelect}>
            {currentSurveyData.data.map((q) => (
              <SurveyItem 
                key={q.id} 
                question={q.question} 
                qidx={q.id} 
                qtype={q.type}
                isPlayer={isPlayer}
                userSelected={userSelectedTempList[q.id] ? userSelectedTempList[q.id].userSelected : undefined}
                />
            ))}
          </SurveyList>

          <ButtonWrapper onClick={handleNextStepSurvey}>
            <LinedButton text="다음" />
          </ButtonWrapper>
        
          <Footer />
        </Main>

      </Background>
    )
  )
}
