import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

export default function useInput
(
  init='', 
  changeOption?:(value:string) => boolean,
  ref?:React.RefObject<HTMLInputElement>,
  option?:{blurUpScroll?:boolean}
):
[string, (e:ChangeEvent<HTMLInputElement>) => void]
{
  const [value, setValue] = useState(init);

  const initScroll = useCallback(() => {
    window.scrollTo({top:0});
  }, [])

  const onChange = (e:ChangeEvent<HTMLInputElement>) => {
    if (!!changeOption && !changeOption(e.target.value)) return;
    setValue(e.target.value);
  }

  useEffect(() => {
    if (!ref || !option) return;
    if (!ref.current) return;

    if (option.blurUpScroll) {
      ref.current.addEventListener('blur', initScroll);
    }
    return () => {
      if (!ref || !option) return;
      if (!ref.current) return;
  
      if (option.blurUpScroll) {
        ref.current.removeEventListener('blur', initScroll);
      }
    }
  }, [])

  return [value, onChange]
}