import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  callback?:() => void;
  color?:string;
}

const HeaderButton = ({children, color, callback}:PropsWithChildren<Props>) => {
  return (
    <ButtonOutlined onClick={callback} color={color}>
      <ButtonInlined color={color}>
        {children}
      </ButtonInlined>
    </ButtonOutlined>
  );
};

export default HeaderButton;

const ButtonOutlined = styled.button`
  position: relative;
  width: 30px;
  height: 32px;
  border-radius: 6px;
  background-color: ${(props:{color?:string}) => props.color ? props.color : '#653789'};
  outline: none;
  border: none;
  cursor: pointer;
`

const ButtonInlined = styled.div`
  width: 30px;
  height: 30px;
  border: 1.5px solid ${(props:{color?:string}) => props.color ? props.color : '#653789'};
  box-sizing: border-box;
  border-radius: 6px;
  position: absolute;
  left: 0px;
  bottom: 2px;
  background-color: #fff;

  & > img {
    width: 6px;
    height: 9px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
