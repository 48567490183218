import styled from 'styled-components';

export const Outlined = styled.div`
  position: relative;
  width: 78px;
  height: 32px;
  border-radius: 6px;
  background-color: #000;
`

export const Inlined = styled.div`
  position: absolute;
  width: 78px;
  height: 30px;
  top: 0px;
  background-color: #fff;
  border-radius: 6px;
  border: 1.55px solid #000;
  font-family: 'Noto Sans';
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  line-height: 30px;
`
