import React from 'react';
import { colors } from 'src/style/styled';
import { IMAGES } from 'src/common/variables/images';
import LinedButton from 'src/component/LinedButton';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import HeaderButton from 'src/component/button/HeaderButton';
import SVG from 'src/assets/images/svg/SVG';
import useMountedUpScroll from 'src/hook/useMountedUpScroll';
import Footer from 'src/component/Footer';
const button = require('src/assets/images/icon/arrow/left_black.png');

export default function NotFound() {
  useMountedUpScroll();
  const history = useHistory();
  return (
    <Background>
      <Wrapper>
        <Header>
          <HeaderButtonWrap>
            <HeaderButton callback={() => history.push('/')}>
              <img src={button} alt="메인페이지로 이동" />
            </HeaderButton>
          </HeaderButtonWrap>
          <SVG.LogoWSTI onClick={() => history.push('/')}/>
        </Header>

        <ImageWrap>
          <div><img src={IMAGES.common[404]} alt="" /></div>
        </ImageWrap>

        <Explain>
          <p>{<SVG.Text404 style={{width:218, height:31, marginBottom:6}}/>}</p>
          <p>일시적인 오류가 발생했습니다.</p>
          <p>서비스 이용에 불편을 드려 죄송합니다.</p>
          <p>잠시후 다시 이용해주세요.</p>
        </Explain>

        <LinedButtonWrap>
          <LinedButton text="홈으로" callback={() => history.push('/')}/>
        </LinedButtonWrap>

      </Wrapper>
      <Footer />
    </Background>
  )
}

const Background = styled.div`
  background: #0F0F0F;
`

const Wrapper = styled.div`
  position: relative;
  max-width: 393px;
  width: 100%;
  min-height: calc(100vh - 47px - 47px - 9px);
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.div`
  margin-bottom: 12px;
`

const HeaderButtonWrap = styled.div`
  position: absolute;
  left: 18px;
  top: 22px;
`

const ImageWrap = styled.div`
  width: 100vw;
  height: 217px;
  background: linear-gradient(90deg, #FE5E94 0%, #AE73D6 100%);
  margin-bottom: 56px;

  & > :first-child {
    background-color: #696969;
    text-align: center;

    & > img {
      height: 211px;
      max-width: 393px;
    }
  }
`

const Explain = styled.div`
  font-weight: 400;
  width: 100%;
  padding-left: 87px;
  margin-bottom: 160px;

  & > p {
    color: #FFF;
  }

  & > :nth-child(2) {
    font-family: 'NanumSquareNeoExtraBold';
    font-weight: 800;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  & > :nth-child(3),
  & > :nth-child(4) {
    font-size: 14px;
    line-height: 24px;
  }
`

const LinedButtonWrap = styled.div`
  position: relative;
  width: 345px;
  height: 48px;
`
