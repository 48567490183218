import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SVG from 'src/assets/images/svg/SVG';
import { GlobalPopupContextDispatch } from 'src/common/variables/context';
import { Button, Container, WikenWrap } from 'src/style/Signup/welcome';
import { AuthContext } from '../context/auth';
import { GlobalPopupContext } from '../popup/GlobalPopup';

interface Props {
  transactionHash:string;
}

const SignUpWelcome = ({transactionHash}:Props) => {

  const { state } = useContext(AuthContext);
  const history = useHistory();
  const { dispatch } = useContext(GlobalPopupContext);

  const PopupTitle = (
    <div style={{
      fontFamily:'NanumSquareNeoHeavy',
      fontWeight:900,
      fontSize:'26px',
      lineHeight:'45px',
      color:'#FFF',
      display:'flex',
      columnGap:'5px',
      justifyContent:'center',
      alignItems:'center',
    }}>
      <div>+10 WIKEN</div>
      <WikenWrap><SVG.WikenCoin width={26} height={26}/></WikenWrap>
    </div>
  )
  
  const PopupInfo = [
    <div>축하합니다!</div>,
    <div>첫 가입기념 10WIKEN이 지급되었습니다!</div>
  ]

  useEffect(() => {
    dispatch({
      type:GlobalPopupContextDispatch.openPopup, 
      payload:{
        title:PopupTitle,
        info:PopupInfo,
        transaction:transactionHash
      }
    })
  }, [])

  if (!state.member) return <></>;

  return (
    <Container>
      <div>
        <div>{(state.member as Member).realName}님</div>
        {/* <ImageWrap><SVG.Pencil /></ImageWrap> */}
      </div>
      <div>회원가입을 축하합니다!</div>
      <div>내 스포츠 기록의 시작, WEDID에 오신 걸 환영해요 :)</div>
      <Button onClick={() => history.push('/')}>
        확인
      </Button>
    </Container>
  );
};

export default SignUpWelcome;