import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { httpErrorCodes } from 'src/common/fetch/errorCode';
import { requestPhoneCert, requestVerifyPhoneCert } from 'src/common/fetch/fetchs';
import { inputKeyDownNumberType, regexPhoneNumber } from 'src/common/util/regex';
import { SignUpContextDispatch } from 'src/common/variables/context';
import useInput from 'src/hook/useInput';
import useTimer from 'src/hook/useTimer';
import { SignupContext, SignUpStep } from 'src/pages/Signup';
import { Container, Heading } from 'src/style/Signup';
import { 
  Input, InputsWrapper, InputWrapper1, 
  InputWrapper2, 
  ReValidateButton, 
  Timer
} from 'src/style/Signup/phoneNumber';
import GlobalLoading from '../GlobalLoading';

const SignUpPhoneNumber = () => {

  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const validateInputWrapperRef = useRef<HTMLDivElement>(null);
  const validateInputRefs = useRef<null[]|HTMLInputElement[]>(Array(6).fill(true).map(() => null));

  const { state, dispatch } = useContext(SignupContext);
  const [phoneNumber, setPhoneNumber] = useInput('', regexPhoneNumber, phoneNumberRef, {blurUpScroll:true});
  const { time, status:timerStatus, startTimer, quitTimer } = useTimer();
  const [validateNumber, setValidateNumber] = useState(Array(6).fill(true).map(() => ''));
  const [checkingValidate, setCheckingValidate] = useState(false);

  const ReValidatePhoneCert = async() => {
    const response = await requestPhoneCert({phoneNumber});
    if (response.error) {
      console.error("인증번호 재요청 에러");
      return;
    }

    setValidateNumber(Array(6).fill(true).map(() => ''));
    startTimer();
    validateInputRefs.current[0]?.focus();
    dispatch({type:SignUpContextDispatch.setPhoneNumberValidate, payload:false});
  }

  const switchFocusValidateInputs = () => {
    const parent = validateInputWrapperRef.current;
    const focusEl = document.activeElement;
    if (!parent) return;
    if (!parent.contains(focusEl)) return;

    let nextFocusSiblingElIdx = 0;
    Array.from(parent.children).forEach((inputWrap, idx) => {
      if (inputWrap.children[0] === focusEl) {
        nextFocusSiblingElIdx = idx + 1;
      }
    })

    if (nextFocusSiblingElIdx == validateNumber.length) {
      verifyPhoneCert();
      return;
    }
    validateInputRefs.current[nextFocusSiblingElIdx]?.focus();
  }

  const verifyPhoneCert = async() => {
    const isEmpty = validateNumber.filter(i => !i).length > 0;
    if (isEmpty) {
      dispatch({type:SignUpContextDispatch.setPhoneNumberValidate, payload:false});
      return;
    }
    if (checkingValidate) return;
    if (!timerStatus) {
      alert('인증시간 만료');
      return;
    }

    const requestBody = { phoneNumber: state.phoneNumber, certNumber: validateNumber.join('') };
    
    setCheckingValidate(true);
    const response = await requestVerifyPhoneCert(requestBody);
    setCheckingValidate(false);

    if (response.error) {
      if (response.error.errorCode === httpErrorCodes.verify.VERIFICATION_CODE_ERROR) {
        alert('인증번호가 불일치합니다.\n다시 시도해주세요.');
      }
      else if (response.error.errorCode === httpErrorCodes.verify.CERT_NUMBER_EXPIRED) {
        alert('인증시간이 만료되었습니다.');
      }
      else if (response.error.errorCode === httpErrorCodes.verify.CERT_NUMBER_TOO_MANY_TRY) {
        alert('인증 횟수가 초과되었습니다.\n잠시 후에 다시 시도해주세요.');
      }
      else {
        alert('인증간에 문제가 발생했습니다.\n새로고침 후 재시도해주세요.');
      }
      return;
    }

    // success
    dispatch({type:SignUpContextDispatch.setPhoneNumberValidate, payload:true});
    quitTimer();
  }

  const onChangeValidateNumber = (e:ChangeEvent<HTMLInputElement>, changeIdx:number) => {
    const value = e.target.value;
    if (value.match(/\D/)) return;
    if (value.length > 1) return;
    setValidateNumber(state => state.map((i, idx) => idx == changeIdx ? value : i));
  }

  useEffect(() => {
    switchFocusValidateInputs();
  }, [validateNumber])
  
  useEffect(() => {
    if (state.step === SignUpStep.PHONENUMBER) {
      phoneNumberRef.current?.focus();
    } else {
      validateInputRefs.current[0]?.focus();
      startTimer();
    }
  }, [state.step])

  useEffect(() => {
    if (phoneNumber.length == 11) {
      dispatch({type:SignUpContextDispatch.setPhoneNumber, payload:phoneNumber});
    } else {
      dispatch({type:SignUpContextDispatch.setPhoneNumber, payload:''});
    }
  }, [phoneNumber])

  return (
    <Container>
      
      {
        state.step === SignUpStep.PHONENUMBER ? (
          <>
            <Heading>
              <div>본인 확인을 위해</div>
              <div>휴대폰 번호가 필요합니다</div>
            </Heading>

            <InputWrapper1>
              <Input
              type="number"
              onKeyPress={inputKeyDownNumberType}
              // placeholder="휴대폰 번호"
              placeholder="01012345678"
              ref={phoneNumberRef}
              value={phoneNumber}
              onChange={setPhoneNumber}
              className="phoneNumber"
              />
              {/* <CountryCode>
                <CountryImg><SVG.Korea /></CountryImg>
                <CountryCodeNumber>+82</CountryCodeNumber>
              </CountryCode> */}
            </InputWrapper1>
          </>
        ) : (
          <>
            <Heading>
              <div>인증번호를 입력해주세요</div>
              <div className="small">+82{state.phoneNumber.slice(1)}로 전송됨</div>
            </Heading>

            <InputsWrapper ref={validateInputWrapperRef}>
              {validateNumber.map((i, idx) => (
                <InputWrapper2 key={idx}>
                  <Input
                  ref={el => {validateInputRefs.current[idx] = el}}
                  type="number"
                  onKeyPress={inputKeyDownNumberType}
                  value={i}
                  onChange={(e) => onChangeValidateNumber(e, idx)}
                  />
                </InputWrapper2>
              ))}
            </InputsWrapper>

            <Timer>{time}</Timer>

            <ReValidateButton
            onClick={ReValidatePhoneCert}
            >인증번호 다시 받기
            </ReValidateButton>

            {checkingValidate && (
              <GlobalLoading />
            )}
          </>
        )
      }

    </Container>
  );
};

export default SignUpPhoneNumber;