import React from "react";
import { surveysData } from "src/common/variables/dataset";
import { IUserSelectedSurveyTest, IUserSelectedSurveyTemp } from "src/type/types";

export default function selectSurvey({
  questionSeq, selectedSeq, setUserSelectedSurveys, setUserSelectedTempList
}: {
  questionSeq: number,
  selectedSeq: number,
  setUserSelectedSurveys: (state:React.SetStateAction<IUserSelectedSurveyTest[]>) => void,
  setUserSelectedTempList: (state:React.SetStateAction<IUserSelectedSurveyTemp>) => void
}) {
  setUserSelectedSurveys((state) => state.concat({
    surveyNumber: questionSeq,
    surveyType: surveysData[questionSeq-1].type,
    userSelected: selectedSeq,
    timestamp: new Date().getTime()
  }))
  setUserSelectedTempList((state) => {
    return {
      ...state,
      [questionSeq]: {
        surveyType: surveysData[questionSeq-1].type,
        userSelected: selectedSeq,
        timestamp: new Date().getTime()
      }
    }
  })
}
