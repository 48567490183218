export const IMAGES = {
  icon: {
    ball: require('src/assets/images/icon/ball.png'),
    refresh: require('src/assets/images/icon/refresh.png'),
    floatingButton: require('src/assets/images/icon/floatingButton.png'),
    share: {
      KAKAO: {
        src: require('src/assets/images/icon/share/kakao.png'),
        info: 'kakao'
      },
      META: {
        src: require('src/assets/images/icon/share/meta.png'),
        info: 'meta'
      },
      TELEGRRAM: {
        src: require('src/assets/images/icon/share/telegram.png'),
        info: 'telegram'
      },
      TWITTER: {
        src: require('src/assets/images/icon/share/twitter.png'),
        info: 'twitter'
      },
      LINK: {
        src: require('src/assets/images/icon/share/link.png'),
        info: 'link'
      },
    },
    arrow: {
      leftBlack: require('src/assets/images/icon/arrow/left_black.png')
    }
  },
  common: {
    MESSI: require('src/assets/images/common/messi.png'),
    son: require('src/assets/images/common/son.png'),
    honaldo: require('src/assets/images/common/honaldo.png'),
    kangte: require('src/assets/images/common/kangte.png'),
    gerrard: require('src/assets/images/common/gerrard.png'),
    splash: require('src/assets/images/common/splash.png'),
    splashWhite: require('src/assets/images/common/splashWhite.png'),
    signupMale: require('src/assets/images/common/signupMale.png'),
    signupFemale: require('src/assets/images/common/signupFemale.png'),
    404: require('src/assets/images/common/404.png'),
    ad: require('src/assets/images/common/ad.png'),
    cardBackground: require('src/assets/images/common/cardBackground.png'),
  },
  logo: {
    logo: require('src/assets/images/logo/logo.png'),
    wstiLogo: require('src/assets/images/logo/logoWSTI.svg'),
  },
}

export const slideImg = [
  IMAGES.common.honaldo,
  IMAGES.common.kangte,
  IMAGES.common.son,
  IMAGES.common.gerrard
]

export const PlayerCardType = {
  DEDICATION: IMAGES.common.kangte,
  LEAD: IMAGES.common.honaldo,
  LEADER: IMAGES.common.gerrard,
  GUTS: IMAGES.common.son
}
