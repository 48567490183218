import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { TCategoryDefaultData } from 'src/common/variables/dataset';
import { PlayerCardType } from 'src/common/variables/images';

export default function CategoryCard({data}:{data:TCategoryDefaultData}){

  const history = useHistory();

  const handleRouteCategory = useCallback(() => {
    history.push(`/mbti/categorys/${data.tendency}`);
  }, [data]);

  return (
    // <CardOutlined onClick={handleRouteCategory}>
    <CardOutlined>
      <Link to={`/mbti/categorys/${data.tendency}`}>
        <CardInlined>
          <div>{data.category}</div>
          <div>{data.explain}</div>
          <MoreSee>더보기</MoreSee>
          <CardIMG>
            <img src={PlayerCardType[data.tendency]} alt={data.category} />
          </CardIMG>
          {/* <CardCover>
            <div>{data.category}</div>
            <div>{data.explain}</div>
            <MoreSee>
              더보기
            </MoreSee>
          </CardCover> */}
        </CardInlined>
      </Link>
    </CardOutlined>
  )
}

const CardOutlined = styled.div`
  position: relative;
  width: 100%;
  max-width: 358px;
  height: 212px;
  background-color: #343434;
  border-radius: 15px;
  cursor: pointer;

  a {
    color: inherit !important;
  }
`

const CardInlined = styled.div`
  position: absolute;
  width: calc(100% - 2px);
  height: 208px;
  background: linear-gradient(90deg, #FE5E94 0%, #AE73D6 100%);
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid #343434;
  border-radius: 15px;
  font-family: 'NanumSquareNeoHeavy';
  padding-top: 34px;
  padding-left: 30px;

  &>:nth-child(1){
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  &>:nth-child(2){
    font-size: 12px;
    width: 135px;
    height: 60px;
    line-height: 18px;
    overfloW: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: keep-all;
    margin-bottom: 10px;
  }
`

const CardIMG = styled.div`
  position: absolute;
  width: 166px;
  height: 207px;
  bottom: 0px;
  right: 25px;
`

const MoreSee = styled.button`
  background: #000;
  width: 108px;
  height: 39px;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  outline: none;
  border: none;
  cursor: pointer;
`
