import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export default function ImageFrame({children}:PropsWithChildren<{}>){

  return (
    <Container>
      <Frame>
        {children}
      </Frame>
      <BehindBackground />
    </Container>
  )
}

const Container = styled.section`
  position: relative;
  width: 360px;
`

const Frame = styled.div`
  position: relative;
  width: 100%;
  background: #FFFFFF;
  border: 2px solid #0F0F0F;
  min-height: 550px;
  z-index: 20;
  border-radius: 14px;
  padding: 22px;
`

const BehindBackground = styled.div`
  position: absolute;
  width: calc(100% - 14px);
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  height: 100px;
  z-index: 10;
  background: linear-gradient(90deg, #FE5E94 0%, #AE73D6 100%);
  border: 2px solid #343434;
  border-radius: 14px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`
