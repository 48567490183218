import styled from "styled-components";

export const Container = styled.div`
  padding: 0 24px;

  & > :nth-child(1),
  & > :nth-child(2) {
    font-weight: 700;
    font-size: 27px;
    line-height: 40px;
    color: #FFF;
  }

  & > :nth-child(1) {
    display: flex;
    column-gap: 2px;
    position: relative;
    /* flex-basis: content; */
    width: max-content;

    &::after {
      /* content: ''; */
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #FFF;
    }
  }

  & > :nth-child(3) {
    font-size: 12px;
    line-height: 30px;
    color: #FFF;
    margin-bottom: 479px;

    @media (max-width:480px) {
      margin-bottom: 300px;
    }
  }
`

export const ImageWrap = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
`

export const Button = styled.button`
  outline: none;
  border: none;
  border-radius: 14px;
  background: #FFF;
  height: 48px;
  width: 100%;
  cursor: pointer;

  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
`

export const WikenWrap = styled.div`
  position: relative;
  width: 26px;
  height: 26px;
  bottom: 3px;
`
