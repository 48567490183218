import React, { useState, useContext } from 'react';
import { GenderType } from 'src/type/types';
import { SignupContext } from 'src/pages/Signup';
import SignUpGenderButton from './SignUpGenderButton';
import { Container, Heading } from 'src/style/Signup';
import { SignupWrapper } from 'src/style/Signup/gender';
import { SignUpContextDispatch } from 'src/common/variables/context';

export default function SignUpGenderView() {

  const { state, dispatch } = useContext(SignupContext);
  const [gender, setGender] = useState<GenderType|null>(null);

  const setGenderType = (gender:GenderType) => {
    dispatch({type:SignUpContextDispatch.setGender, payload: gender === GenderType.Male ? 'MALE' : 'FEMALE'});
    setGender(gender);
  }

  return (
    <Container>
      
      <Heading>
        <div>{state.username} 님,</div>
        <div>어떤 성별이신가요?</div>
      </Heading>

      <SignupWrapper>
        
        <SignUpGenderButton
        gender={GenderType.Male}
        selected={gender === GenderType.Male}
        callback={() => setGenderType(GenderType.Male)} />
        
        <SignUpGenderButton 
        gender={GenderType.FeMale}
        selected={gender === GenderType.FeMale}
        callback={() => setGenderType(GenderType.FeMale)} />

      </SignupWrapper>
    </Container>
  )
}
