import React, { useEffect, useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PlayerCardType } from 'src/common/variables/images';
import { RouteParams } from 'src/type/types';
import LinedButton from 'src/component/LinedButton';
import CategoryInfo from 'src/component/CategoryInfo';
import ImageFrame from 'src/component/ImageFrame';
import {
  Background,
  ButtonsWrapper,
  ImageWrap,
  CategoryTitle,
  Wrapper
} from 'src/style/category';
import useMountedUpScroll from 'src/hook/useMountedUpScroll';
import MainHeader from 'src/component/Header/MainHeader';
import { CategoryDefaultData } from 'src/common/variables/dataset';
import { StoryWrapper } from 'src/style/mbti';
import { ButtonWrapper } from 'src/style/categorys';
import Footer from 'src/component/Footer';

export default function Category() {
  useMountedUpScroll();

  const history = useHistory();
  const { tendency } = useParams<RouteParams>();
  const category = useMemo(() => {
    return CategoryDefaultData.findIndex(i => i.tendency === tendency) !== -1 ?
    CategoryDefaultData.filter(i => i.tendency === tendency)[0] : undefined;
  }, [tendency])

  useEffect(() => {
    if (category === undefined) {
      history.push('/');
    }
  }, [category])

  if (category === undefined) return <></>;
  
  return (
    <>
      <Background>

        <Wrapper>

          <MainHeader
          backButton={true}
          myInfoButton={true}
          logoStyle='White'
          styles={{marginBottom:70}}/>

          <CategoryTitle>{category.category} 스포츠인!</CategoryTitle>

          <ImageWrap>
            <img src={PlayerCardType[category.tendency]} alt={category.category} />
          </ImageWrap>

          <StoryWrapper>
            <ImageFrame>
              <CategoryInfo 
              category={category.category}
              explain={category.explain}
              info={category.info.join('\n\n')} />
            </ImageFrame>
          </StoryWrapper>

          <ButtonsWrapper>
            <ButtonWrapper>
              <Link to="/">
                <LinedButton text="홈으로 가기" />
              </Link>
            </ButtonWrapper>
            <ButtonWrapper>
              <Link to="/mbti">
                <LinedButton text="테스트 시작" />
              </Link>
            </ButtonWrapper>
          </ButtonsWrapper>

        </Wrapper>
        <Footer />
        
      </Background>
    </>
  )
}
