import React, { useState, useEffect, useRef, useCallback, useContext, useMemo } from 'react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { IMAGES, PlayerCardType } from 'src/common/variables/images';
import LinedButton from 'src/component/LinedButton';
import CategoryInfo from 'src/component/CategoryInfo';
import ImageFrame from 'src/component/ImageFrame';
import {
  AddMoreButtonWrapper, Background, CardWrapper, 
  ChartWrapper, FloatingButton, FreeSection, PaidSection, 
  SportCharacter, Title, Wrapper,
} from 'src/style/category';
import { 
  ADBack, ADHeader, ADLink, 
  AbilityHeader, AbilityList, AbilityWrapper, 
  ButtonWrapper, ShareHeader, ShareWrapper, 
  ShareListWrapper, AdSection, ExplainFrame, Explain, CategorySlideWrap, ButtonsWrapper
} from 'src/style/result';
import ShareList from 'src/component/ShareList';
import AbilityItem from 'src/component/AbilityItem';
import { AuthContext, getCookieValue, initCookieValue } from 'src/component/context/auth';
import Card from 'src/component/Card';
import { DivideLine, StoryWrapper } from 'src/style/mbti';
import { requestFullVersionUnlock, requestGetResult } from 'src/common/fetch/fetchs';
import Loading from 'src/component/Loading';
import GlobalLoading from 'src/component/GlobalLoading';
import { GlobalPopupContext } from 'src/component/popup/GlobalPopup';
import { GlobalPopupContextDispatch } from 'src/common/variables/context';
import { httpErrorCodes } from 'src/common/fetch/errorCode';
import ChartCustom from 'src/component/ChartCustom';
import { COOKIE_TRANSACTION_HASH } from 'src/common/variables/constant';
import useMountedUpScroll from 'src/hook/useMountedUpScroll';
import { myAbilityTexts, mySportCharacterTexts } from 'src/common/variables/dataset';
import MainHeader from 'src/component/Header/MainHeader';
import CategorySlide from 'src/component/CategorySlide';
import Footer from 'src/component/Footer';

interface IResultProps {
  hashId: string;
}

// 주소에 해시키가 있는 경우 로그인 여부와 상관없이 무조건 해시키 기준으로 데이터를 요청한다면
// 로그인 상태에서 타인의 공유된 페이지를 들어가도 데이터를 확인가능

export default function Results(props: RouteComponentProps<IResultProps>) {
  useMountedUpScroll();

  const { state:AuthState } = useContext(AuthContext);
  const { dispatch:GlobalPopupDispatch } = useContext(GlobalPopupContext);

  const history = useHistory();
  // const location = useLocation();
  // const { state:locationState } = location;
  const [isViewDiscountPopupFromSubmit, setIsViewDiscountPopupFromSubmit] = useState(false);
  const [isViewDiscountPopupFromFullVersion, setIsViewDiscountPopupFromFullVersion] = useState(false);
  const transactionCookie = useMemo(() => {
    const hash = getCookieValue(COOKIE_TRANSACTION_HASH);
    if (hash) {
      initCookieValue(COOKIE_TRANSACTION_HASH);
    }
    return hash;
  }, [])

  const params = useParams<{hashPath?:string}>();
  const [result, setResult] = useState<WSTIResult>();
  const [shareCopy, setShareCopy] = useState(false);
  const shareRef = useRef<HTMLTableSectionElement>(null);

  const myAbility = useMemo(() => {
    return !result ? [] : !result.isFull ? [] : formattingAbility(result);
  },[result]);

  const myChartAbility = useMemo(() => {
    return !result ? [] : !result.isFull ? [] : formattingCharacteristic(result)
  }, [result])

  const abilityRef = useRef<HTMLUListElement>(null);
  const viewportHeight = useRef<number>(window.innerHeight);
  const [abilityAnimation, setAbilityAnimation] = useState(false);
  const [detailResultFetching, setDetailResultFetching] = useState(false);
  
  const onAnimation = useCallback(() => {
    const currentElementTop = abilityRef.current?.getBoundingClientRect().top as number;
    if (!abilityAnimation) {
      if (currentElementTop / viewportHeight.current < 0.9) {
        setAbilityAnimation(true);
      }
    } else {
      if (currentElementTop > viewportHeight.current) {
        setAbilityAnimation(false);
      }
    }
  }, [abilityAnimation]);

  const getWstiResult = async(hashPath?:string) => {
    const response = await requestGetResult(hashPath);

    if (response.error || !response.data) {
      alert('테스트 결과가 없거나 요청 중 문제가 발생했습니다.');
      history.push('/');
      return;
    }

    setResult(response.data.data);
  }

  const advertise = () => {
    const userAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Web';
    if (userAgent === 'Web') {
      window.open('https://play.google.com/store/apps/details?id=io.projectwith.with_flutter', '_blank');
    } else {
      const userAgent = navigator.userAgent.toLowerCase();
      const isAndroid = userAgent.indexOf('android') > -1;
      const isIos = userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipod') > -1;
      if (isAndroid) {
        window.open('https://play.google.com/store/apps/details?id=io.projectwith.with_flutter', '_blank');
      } else if (isIos) {
        window.open('https://apps.apple.com/kr/app/위드-project-with/id1484254608', "_blank");
      } else {
        window.open('https://play.google.com/store/apps/details?id=io.projectwith.with_flutter', '_blank');
      }
    }
  }

  const paymentDetailResult = async() => {
    setDetailResultFetching(true);
    const response = await requestFullVersionUnlock();
    setDetailResultFetching(false);
    
    if (response.error) {
      if (response.error.errorCode === httpErrorCodes.verify.WSTI_PAYMENT_FAIL_EXCEPTION) {
        alert('wiken 결제를 실패하였습니다.\n관리자에게 문의해주세요.');
      }
      else if (response.error.errorCode === httpErrorCodes.verify.WIKEN_LACK_EXCEPTION) {
        alert('wiken 이 부족합니다.\n관리자에게 문의해주세요.');
      }
      else if (response.error.errorCode === httpErrorCodes.verify.WSTI_ALREADY_PAID_EXCEPTION) {
        alert('이미 결제되었습니다.');
        window.location.reload();
      }
      else {
        alert('문제가 발생하였습니다.\n관리자에게 문의해주세요.');
      }
      return;
    }

    if (!result) return;
    const fullData = response.data?.data as WSTIResult;
    setResult({
      ...result,
      isFull: true,
      characteristic: fullData.characteristic,
      outputData: fullData.outputData,
      transactionHash: fullData.transactionHash
    })
  }

  const viewDetailResult = () => {
    GlobalPopupDispatch({
      type: GlobalPopupContextDispatch.openPopup,
      payload: {
        popupType: 'CONFIRM',
        callback: paymentDetailResult,
        title: <>추가 정보 확인을 위해 5WIKEN이 사용됩니다. 사용하시겠습니까?</>,
        info: [<>가입시 지급된 위켄으로 자동 차감됩니다.</>]
      }
    })
  }

  const sharedCopy = () => {
    setShareCopy(true);
    navigator.clipboard.writeText('https://survey.projectwith.org')
    setTimeout(() => {
      setShareCopy(false);
    }, 1000)
  }

  const moveToShareSection = () => {
    const section = shareRef.current;
    if (!section) return;

    const top = section.getBoundingClientRect().top;
    const currentScrollY = window.scrollY;

    requestAnimationFrame(() => window.scrollTo({top:currentScrollY + top - 7 - 34, behavior:'smooth'}));
    // window.scrollTo({top:currentScrollY + top - 7 - 34, behavior:'smooth'});
  }

  useEffect(() => {
    let transactionHash = '';
    if (transactionCookie && !isViewDiscountPopupFromSubmit) {
      setIsViewDiscountPopupFromSubmit(true);
      transactionHash = transactionCookie as string;
    }
    else if (result?.transactionHash && !isViewDiscountPopupFromFullVersion) {
      setIsViewDiscountPopupFromFullVersion(true);
      transactionHash = result.transactionHash;
    }

    if (transactionHash) {
      GlobalPopupDispatch({
        type: GlobalPopupContextDispatch.openPopup,
        payload: {
          title: <>차감 완료</>,
          info: [<>5 WIKEN이 자동 차감되었습니다.</>],
          transaction: transactionHash,
        }
      })
    }
  }, [
    isViewDiscountPopupFromSubmit, 
    isViewDiscountPopupFromFullVersion,
    result?.transactionHash
  ])

  useEffect(() => {
    window.addEventListener('scroll', onAnimation);
    return () => window.removeEventListener('scroll', onAnimation);
  }, [abilityAnimation])

  useEffect(() => {
    if (result?.isFull) {
      onAnimation();
    }
  }, [result])

  useEffect(() => {
    if (!AuthState.signChecked) return;

    const { hashPath } = params;

    if (AuthState.member) {
      // 해시키가 있으면 로그인이 되어 있어도 무조건 해시키로 요청
      if (hashPath) {
        getWstiResult(hashPath);
      }
      // 로그인 상태로 방문시 -> 결과 요청 (GET, only token)
      else {
        getWstiResult();
      }
    }
    if (!AuthState.member) {
      // 비로그인 상태로 방문시 -> 결과 요청 (GET, add params)
      // 비로그인 상태에서 hash키가 없으면 redirect
      const { hashPath } = params;
      if (!hashPath) {
        history.push('/');
        return;
      }
      getWstiResult(hashPath);
    }
  }, [AuthState])

  if (!AuthState.signChecked || !result) return <GlobalLoading />

  return (
    <Background>
      <Wrapper>

        <MainHeader
        backButton={result.isMine}
        myInfoButton={result.isMine}
        logoStyle="White"
        styles={{marginBottom:30}} />

        {/* 요금 부과 없이 기본으로 볼 수 있는 섹션 */}
        <FreeSection>
          <Title>
            <div>{result.name}님! 당신은</div>
            <div>{result.wstiDetailView.title} 스포츠인!</div>
          </Title>

          <CardWrapper>
            <Card playerType={
              result.wstiDetailView.title ?
              result.wstiDetailView.title + ' 스포츠인' : ''
              }>
              {result.wstiDetailView.type ? (
                <img src={PlayerCardType[result.wstiDetailView.type]} alt="" />
              ) : <></>}
            </Card>
          </CardWrapper>

          <StoryWrapper>
            <ImageFrame>
              <CategoryInfo 
              category={result.wstiDetailView.title}
              info={result.wstiDetailView.description}
              explain={result.wstiDetailView.subTitle}
              />
            </ImageFrame>
          </StoryWrapper>
          
          {AuthState && result.isMine && !result.isFull && (
            <AddMoreButtonWrapper>
              <LinedButton
              text={!detailResultFetching ? '자세한 결과 더보기' : <Loading />}
              callback={viewDetailResult}
              styles={{
                background: 'linear-gradient(90deg, rgba(255, 82, 140, 0.7) 0%, rgba(167, 79, 227, 0.7) 100%)',
                fontSize: 16,
                border: '2px solid #FE5E94'
              }} />
            </AddMoreButtonWrapper>
          )}

        </FreeSection>

        <DivideLine color="rgba(255, 255, 255, 0.5)"/>
        
        {/* 추가 wiken 을 결제했을 때 볼 수 있는 섹션 */}
        {result.isFull && (
          <PaidSection>
            <AbilityWrapper>
              <AbilityHeader>나의 역량 지수</AbilityHeader>
              
              <AbilityList ref={abilityRef}>
                { myAbility.map((v, idx) => (
                  <AbilityItem type={v.type} ability={v.ability} key={idx} view={!!abilityAnimation}/>
                ))}
              </AbilityList>

              <ExplainFrame>
                <div className="heading">
                  <span>나의 역량 지수 항목 살펴보기</span>
                </div>
                <ul>
                  {myAbilityTexts.map(i => (
                    <Explain key={i.title}>
                      <div className="title">{i.title}</div>
                      <div className="info">{i.info}</div>
                    </Explain>
                  ))}
                </ul>
              </ExplainFrame>
            </AbilityWrapper>

            <DivideLine color="rgba(255, 255, 255, 0.5)"/>

            <SportCharacter>
              <div className="title">나의 스포츠 성격 특성</div>
              <ChartWrapper>
                <ChartCustom 
                data={myChartAbility}
                styles={{width:299, height:292}}
                />
              </ChartWrapper>
              
              <ExplainFrame>
                <div className="heading">
                  <span>스포츠 성격 유형 살펴보기</span>
                </div>
                <ul>
                  {mySportCharacterTexts.map(i => (
                    <Explain key={i.title}>
                      <div className="title">{i.title}</div>
                      <div className="info">{i.info}</div>
                    </Explain>
                  ))}
                </ul>
              </ExplainFrame>

            </SportCharacter>

            <DivideLine color="rgba(255, 255, 255, 0.5)"/>

          </PaidSection>
        )}

        <ShareWrapper ref={shareRef}>
          <ShareHeader>내 테스트 결과 공유하기</ShareHeader>
          <ShareListWrapper>
            <ShareList hashPath={result.url}/>
          </ShareListWrapper>
          <div className="alert">테스트 결과 공유시 익명으로 공유됩니다.</div>
        </ShareWrapper>
        
        <ButtonsWrapper>
          <ButtonWrapper>
            <LinedButton
            text={!shareCopy ? `테스트 공유하기` : '링크 복사 완료!'}
            callback={sharedCopy}/>
          </ButtonWrapper>

          <ButtonWrapper>
            <LinedButton 
            text="홈으로 가기" 
            callback={() => history.push('/')}/>
          </ButtonWrapper>
        </ButtonsWrapper>

        <DivideLine color="rgba(255, 255, 255, 0.5)"/>

        <AdSection>
          <ADHeader>다른 유형 구경하기</ADHeader>
          <CategorySlideWrap>
            <CategorySlide />
          </CategorySlideWrap>

          <ADHeader>
            <p>더 많은 스포츠 활동을</p>
            <p>관리하고 싶다면?</p>
          </ADHeader>
          <ADLink onClick={advertise}>
            <ADBack>
              <img src={IMAGES.common.ad} alt="클릭시 --로 이동됩니다" />
            </ADBack>
          </ADLink>
        </AdSection>

        <FloatingButton onClick={moveToShareSection}>
          <img src={IMAGES.icon.floatingButton} alt="클릭시 공유 기능으로 이동됩니다" />
        </FloatingButton>
        
        <Footer />

      </Wrapper>
    </Background>
  )
}

const formattingAbility = (result:WSTIResult) => {
  return [
    {type:'리더쉽', ability:result.outputData?.leadership ?? 0}, 
    {type:'자기조절', ability:result.outputData?.selfControl ?? 0}, 
    {type:'정신적 강인함', ability:result.outputData?.mental ?? 0}, 
    {type:'팀웍', ability:result.outputData?.teamwork ?? 0}, 
    {type:'경기력', ability:result.outputData?.performance ?? 0}, 
  ]
}

const formattingCharacteristic = (result:WSTIResult) => {
  return [
    {key:'성실성', value:result.characteristic?.sincerity},
    {key:'도전', value:result.characteristic?.challenge},
    {key:'인내와 끈기', value:result.characteristic?.patience},
    {key:'친화성', value:result.characteristic?.affinity},
    {key:'정서적 안정성', value:result.characteristic?.emotionalStability},
    {key:'외향성', value:result.characteristic?.extraversion},
  ]
}
