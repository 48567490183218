import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import { GlobalPopupContextDispatch } from 'src/common/variables/context';
import { slideImg } from 'src/common/variables/images';
import { AuthContext } from 'src/component/context/auth';
import Footer from 'src/component/Footer';
import GlobalLoading from 'src/component/GlobalLoading';
import MainHeader from 'src/component/Header/MainHeader';
import { GlobalPopupContext } from 'src/component/popup/GlobalPopup';
import useMountedUpScroll from 'src/hook/useMountedUpScroll';
import { 
  Container, 
  ImageWrapper, 
  IntroTitle, 
  ListWrapper, 
  Main, 
  Button,
  Explain,
  ButtonWrapper,
  Heading,
  Greet,
 } from 'src/style/Intro';

const slideSettings:Settings = {
  dots: true,
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  appendDots: dots => (
    <div
      style={{
        position: "absolute",
      }}
    >
      <ul style={{
        display: "flex",
        justifyContent: "center",
        columnGap: "6px",
        position: "absolute",
        bottom: "-142px", 
        left: "50%",
        transform: "translateX(-50%)"
      }}>
        {dots} 
      </ul>
    </div>
  ),
  customPaging: () => (
    <div
      style={{
        width: "8px",
        height: "8px",
        backgroundColor: "#3E3E3E",
        borderRadius: "4px"
      }}
    />
  )
}

const Intro = () => {
  useMountedUpScroll();

  const history = useHistory();
  const { state:AuthState } = useContext(AuthContext);
  const [readyWSTI, setReadyWSTI] = useState(false);
  const { dispatch } = useContext(GlobalPopupContext);

  const onReadyWSTI = () => {
    setReadyWSTI(state => !state);
  }

  const signUp = () => {
    dispatch({
      type: GlobalPopupContextDispatch.openPopup,
      payload: {
        title: <>금방 끝나요!</>,
        info: [
          <>회원가입을 위한 평균 소요시간은</>,
          <>단 30초! 계속 진행해주실거죠? :)</>
        ],
        callback: () => history.push('/signup'),
        callbackText: '그럼요!',
        styles: {
          titleTextAlign: 'center'
        }
      }
    })
    // history.push('/signup');
  }

  const signIn = () => {
    history.push('/signin')
  }

  const startTest = () => {
    history.push('/mbti')
  }

  if (!AuthState.signChecked) return <GlobalLoading />

  return (
    <Container>
      <Main>

        <MainHeader styles={{
          marginBottom: AuthState.member ? 34 : 64
        }}/>

        {AuthState.member && (
          <Heading>
            <div>스포츠 심리테스트</div>
            <div>Sports MBTI</div>
          </Heading>
        )}

        <ListWrapper className={AuthState.member ? 'signIn' : ''}>
          <Slider {...slideSettings}>
            {slideImg.map((img, idx) => (
              <ImageWrapper key={idx}>
                <img src={img} alt="" />
              </ImageWrapper>
            ))}
          </Slider>
        </ListWrapper>

        {AuthState.member ? (
          <Greet>{AuthState.member.realName}님 환영합니다!</Greet>
        ) : (
          <IntroTitle>스포츠 심리테스트</IntroTitle>
        )}

        {AuthState.member ? (
          <ButtonWrapper>
            <Button onClick={startTest}>시작</Button>
          </ButtonWrapper>
        ) : (
          <>
            <ButtonWrapper>
              {!readyWSTI ? (
                <Button onClick={onReadyWSTI}>시작</Button>
              ) : (
                <>
                  <Button onClick={signUp}>회원가입</Button>
                  <Button onClick={signIn}>로그인</Button>
                </>
              )}
            </ButtonWrapper>

            <Explain>
              서비스 시작시&nbsp;
              <a target="_blank" href='https://main.projectwith.io/policy?type=service'>이용약관</a>&nbsp;
              및&nbsp;
              <a target="_blank" href='https://main.projectwith.io/policy?type=privacy'>개인정보 처리방침</a>&nbsp;
              동의로 간주합니다.
            </Explain>
          </>
        )}

      </Main>
      <Footer />
    </Container>
  );
};

export default Intro;