import React from 'react';
import { Link } from 'react-router-dom';
import SVG from 'src/assets/images/svg/SVG';

import { ButtonInlined, ButtonOutlined } from './BackBtn';

interface Props {
  color?:string;
}

const MyInfoBtn = ({
  color = '#000'
}:Props) => {
  return (
    <ButtonOutlined color={color}>
      <Link to="/myinfo">
        <ButtonInlined color={color}>
          <SVG.MyInfo style={{width:18, height:18, top:'50%', left:'50%', transform:'translate(-50%, -50%)', position:'absolute'}}/>
        </ButtonInlined>
      </Link>
    </ButtonOutlined>
  );
};

export default MyInfoBtn;