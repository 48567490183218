import styled, { css } from "styled-components"

export const InputWrapper = styled.div`
  position: relative;
  min-width: 72px;
  max-width: 132px;
  width: 0%;
  height: 44px;
  margin-bottom: 40px;

  ${(props:{length:number}) => {
    if (props.length > 6) {
      return css`
        width: 100%;
      `
    }
    if (props.length > 3) {
      return css`
        width: 100px;
      `
    }
  }}

  & input {
    padding: 0 14px;
  }

  .warning {
    position: absolute;
    font-size: 12px;
    color: #C92B2B;
    left: -10px;
    bottom: -50px;
    width: max-content;
  }
`

export const Warning = styled.div`
  font-size: 12px;
  color: #C92B2B;
  text-align: center;
  width: max-content;
  line-height: 24px;
`
