import React from 'react';
import { IMAGES } from 'src/common/variables/images';
import styled from 'styled-components';
import { ButtonInlined, ButtonOutlined } from './BackBtn';

export default function SurveyRefresh({refresh}: {refresh: () => void}){

  return (
    <ButtonOutlined color="#000" onClick={refresh}>
      <ButtonInlined color="#000">
        <img src={IMAGES.icon.refresh} alt="클릭시 모든 질문이 초기화 됩니다." style={style}/>
      </ButtonInlined>
    </ButtonOutlined>
  )
}

const style = {
  width: '13px',
  height: '12px',
}
