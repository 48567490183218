import React, { useContext } from 'react';
import { useLocation  } from 'react-router-dom';
import SignIn from 'src/pages/SignIn';
import NotFound from 'src/pages/NotFound';
import { AuthContext } from 'src/component/context/auth';
import GlobalLoading from 'src/component/GlobalLoading';

interface Props {
  exact?: boolean;
  path: string;
  component: JSX.Element;
}

const AuthRoute = ({exact, path, component}:Props) => {

  const { state } = useContext(AuthContext);
  const router = useLocation();
  
  if (exact && (router.pathname + router.search) !== path) return <NotFound />
  if (!state.signChecked) return <GlobalLoading />
  if (state.signChecked && !state.member) return <SignIn />

  return <>{component}</>
};

export default AuthRoute;