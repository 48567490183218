import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import BallList from './BallList';
import SVG from 'src/assets/images/svg/SVG';

export default function SurveySpinner() {

  const [loadingDotCnt, setLoadingDotCnt] = useState(1);

  useEffect(() => {
    let dotCnt = 1;
    const interval = setInterval(() => {
      setLoadingDotCnt(dotCnt + 1 < 4 ? dotCnt + 1 : 1);
      dotCnt = dotCnt + 1 < 4 ? dotCnt + 1 : 1;
    }, 1000);

    return () => clearInterval(interval);
  }, [])
  
  return (
    <Wrapper>
      <Logo><SVG.LogoWSTIWhite /></Logo>
      <BallList time={2}/>
      <Loading dot={Array(loadingDotCnt).fill(true).map(() => '. ').join('')}>
        LOADING
      </Loading>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 393px;
  background: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`

const Loading = styled.div`
  font-family: 'NanumSquareNeoHeavy';
  font-size: 24px;
  line-height: 30px;
  margin-top: 150px;
  color: #FFF;
  position: relative;

  ${(props:{dot:string}) => {
    return css`
      &::after {
        content: '${props.dot}';
        position: absolute;
        left: 113px;
        width: max-content;
      }
    `
  }}
`

const Logo = styled.div`
  position: relative;
  width: 74px;
  height: 36px;
  margin-bottom: 150px;
`